
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import { trackLink } from '../../shared/general/tracking/TrackingService'
import EButtonVariant from '../../shared/general/enums/EButtonVariant'
import ELinkTarget from '../../shared/general/enums/ELinkTarget'
import { TIcon } from '../../shared/general/types/TIcon'
import {
  buttonSizeLarge, buttonSizeSmall, buttonDefaultStyle, buttonVariantStyles,
} from '../../shared/general/services/BaseButtonStyles'

@Component({
  name: 'BaseButton',
  components: { BaseIcon: () => import('./BaseIcon.vue') },
})
export default class BaseButton extends Vue {
  @Prop({ default: '' }) link! : string

  @Prop({ default: '' }) target! : ELinkTarget

  @Prop() title ?: string

  @Prop({ default: '' }) label! : string

  @Prop({ default: '' }) icon! : string

  @Prop({ default: 'light' }) iconType! : TIcon

  @Prop({ default: false }) rotateIcon! : boolean

  @Prop({ default: false }) disabled! : boolean

  @Prop({ default: false }) reversed! : boolean

  @Prop({ default: EButtonVariant.Default }) variant! : EButtonVariant

  @Prop({ default: false }) inverted ?: boolean

  @Prop({ default: 'large' }) size ?: 'small' | 'large'

  @Prop({ default: true }) showLabel! : boolean

  private get style () : string {
    const state = this.disabled ? 'disabled' : 'default'
    const size = this.size === 'small' ? buttonSizeSmall : buttonSizeLarge

    if (this.inverted) return `${buttonDefaultStyle} ${size} ${buttonVariantStyles[this.variant].inverted[state]}`
    return `${buttonDefaultStyle} ${size} ${buttonVariantStyles[this.variant][state]}`
  }

  // Can return 'false', so target attribute won't be rendered when there's no link
  private get getTarget () : ELinkTarget | false {
    if (!this.link) return false
    return this.target
  }

  private track () : void {
    if (!this.link) return

    const data = {
      link_text: this.label,
      link_url: this.link,
    }

    trackLink(this.$el as HTMLElement, data)
  }

  @Emit('click')
  private click (event : Event) : Event {
    this.track()
    return event
  }
}
