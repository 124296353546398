
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component, Watch } from 'nuxt-property-decorator'
import IOption from '../../../shared/general/interfaces/IOption'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import particulateData from '../../../static/assets/particulate-matter/particulates-data_64961'

@Component({
  name: 'StParticulateMatter',
  components: {
    BaseGridLayout,
    BaseButton: () => import('../../base/BaseButton.vue'),
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
    BaseInput: () => import('../../base/form/BaseInput.vue'),
    BaseRadio: () => import('../../base/form/BaseRadio.vue'),
    BaseDropdown: () => import('../../base/form/BaseDropdown.vue'),
    BaseAccordion: () => import('../../base/accordion/BaseAccordion.vue'),
    BaseAccordionElement: () => import('../../base/accordion/BaseAccordionElement.vue'),
  },
})
export default class StParticulateMatter extends FSXABaseSection {
  private stickerResult : number = -2

  private vehicle : string = ''

  private motor : 'benzin' | 'diesel' | '' = ''

  private emissionNumber : string = ''

  private pmLevel : string = ''

  private formSubmitted : boolean = false

  private formHasError : boolean = false

  private pmLevelOptions : IOption[] = [
    {
      value: '--',
      label: 'kein Partikelminderungssystem',
    },
    {
      value: 'pm01',
      label: 'PM01 / PMK01',
    },
    {
      value: 'pm0',
      label: 'PM0 / PMK0',
    },
    {
      value: 'pm1',
      label: 'PM1 / PMK1',
    },
    {
      value: 'pm2',
      label: 'PM2 / PMK2',
    },
    {
      value: 'pm3',
      label: 'PM3 / PMK3',
    },
    {
      value: 'pm4',
      label: 'PM4 / PMK4',
    },
    {
      value: 'pm5',
      label: 'PM5',
    },
  ]

  private resultTextImage = {
    '-6': {
      text: 'Oldtimer (gemäß §2 Nr.22 FZV), die ein Kennzeichen nach § 9 Abs. 1 oder § 17 FZV führen, '
        + 'sind von der Kennzeichnung ausgenommen.',
    },
    '-5': {
      text: 'Die gewählte Kombination von Fahrzeugart und Emissionsschlüsselnummer ist nicht möglich! '
        + 'Bitte überprüfen Sie die Eingabe!',
    },
    '-3': {
      text: 'Die gewählte Kombination von motor und Emissionsschlüssel ist nicht möglich. Bitte '
        + 'überprüfen Sie die Eingabe!',
    },
    '-2': {
      text: 'Die eingegebene Emissionsschlüsselnummer ist nicht gültig. Bitte überprüfen Sie die Eingabe!',
    },
    '-1': {
      text: 'Die gewählte Kombination von Fahrzeugart, PM-Stufe/-Klasse und Emissionsschlüsselnummer ist nicht '
        + 'möglich / nicht vorgesehen. Bitte überprüfen Sie die Eingabe!',
    },
    0: {
      text: 'Ihr Fahrzeug kann leider keine Plakette erhalten.',
      alt: 'Keine Feinstaubplakette möglich',
      title: 'Keine Feinstaubplakette möglich',
      src: this.image('no-sticker'),
    },
    2: {
      text: 'Es kann eine Feinstaubplakette "2 rot" zugeteilt werden.',
      alt: 'Feinstaubplakette rot',
      title: 'Feinstaubplakette rot',
      src: this.image('red-sticker'),
    },
    3: {
      text: 'Es kann eine Feinstaubplakette "3 gelb" zugeteilt werden.',
      alt: 'Feinstaubplakette gelb',
      title: 'Feinstaubplakette gelb',
      src: this.image('yellow-sticker'),
    },
    4: {
      text: 'Es kann eine Feinstaubplakette "4 grün" zugeteilt werden.',
      alt: 'Feinstaubplakette grün',
      title: 'Feinstaubplakette grün',
      src: this.image('green-sticker'),
    },
    5: {
      text: 'Fahrzeugen mit einem zulässigen Gesamtgewicht von höchstens 2500 kg kann eine Plakette der '
        + 'Schadstoffgruppe "3 gelb" zugeteilt werden. Fahrzeugen mit einem zulässigen Gesamtgewicht von '
        + 'über 2500 kg kann eine Plakette der Schadstoffgruppe "4 grün" zugeteilt werden.',
      alt: 'Feinstaubplakette gelb-grün',
      title: 'Feinstaubplakette gelb-grün',
      src: this.image('yellow-green-sticker'),
    },
  }

  private image (name : string, type ?: string) : string {
    if (!name) return ''
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`../../../static/assets/particulate-matter/${name}.${type || 'gif'}`)
  }

  private get resultObject () : Record<string, string> {
    return this.resultTextImage[this.stickerResult] || {
      text: '', alt: '', title: '', src: '',
    }
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private validateForm () : boolean {
    if (!this.vehicle || !this.motor || !this.emissionNumber) return false
    if (this.motor === 'diesel' && !this.pmLevel) return false

    return true
  }

  private calculateSticker () : void {
    this.formSubmitted = true
    this.stickerResult = -2

    if (!this.validateForm()) {
      this.formHasError = true
      return
    }

    this.formHasError = false

    let col : string[] | undefined
    let sticker : string | undefined
    let i : number

    for (i = 1; i < particulateData.length; i += 1) {
      col = particulateData[i].split(';')
      if (col?.[0] === `|${this.emissionNumber}|`) {
        break
      }
    }

    if (i === particulateData.length) {
      sticker = '-2'
    } else {
      switch (this.vehicle) {
        case 'pkw':
          switch (this.motor) {
            case 'benzin':
              sticker = col?.[1]
              break
            case 'diesel':
              switch (this.pmLevel) {
                case '--':
                  sticker = col?.[2]
                  break
                case 'pm01':
                  sticker = col?.[3]
                  break
                case 'pm0':
                  sticker = col?.[4]
                  break
                case 'pm1':
                  sticker = col?.[5]
                  break
                case 'pm2':
                  sticker = col?.[6]
                  break
                case 'pm3':
                  sticker = col?.[7]
                  break
                case 'pm4':
                  sticker = col?.[8]
                  break
                case 'pm5':
                  sticker = col?.[9]
                  break
                default:
                  sticker = '-2'
                  break
              }
              break
            default:
              sticker = '-2'
              break
          }
          break
        case 'nfz':
          switch (this.motor) {
            case 'benzin':
              sticker = col?.[10]
              break
            case 'diesel':
              switch (this.pmLevel) {
                case '--':
                  sticker = col?.[11]
                  break
                case 'pm01':
                  sticker = col?.[12]
                  break
                case 'pm0':
                  sticker = col?.[13]
                  break
                case 'pm1':
                  sticker = col?.[14]
                  break
                case 'pm2':
                  sticker = col?.[15]
                  break
                case 'pm3':
                  sticker = col?.[16]
                  break
                case 'pm4':
                  sticker = col?.[17]
                  break
                case 'pm5':
                  sticker = col?.[18]
                  break
                default:
                  sticker = '-2'
                  break
              }
              break
            default:
              sticker = '-2'
              break
          }
          break
        default:
          sticker = '-2'
          break
      }
    }

    this.stickerResult = parseInt(sticker || '-2', 10)
  }

  @Watch('motor')
  private motorChanged () : void {
    // reset pmLevel if motor changed
    this.pmLevel = ''
  }
}
