import ICtaParams from './ICtaParams'
import IBaseParams from './IBaseParams'
import INavLevelParams from './INavLevelParams'
import ILinkBaseParams, {
  IFormButtonParams,
  IFormInputParams,
  ILinkDirectionParams,
  ILinkDownloadBaseParams,
  ILinkDownloadParams,
  ILinkExternalParams,
  ILinkMailParams,
  ILinkPhoneParams,
} from './ILinkParams'
import { IDataLayer } from './IDataLayer'
import StoreSingleton from '../services/StoreSingleton'
import isExternalURL from '../../fsxa/services/ExternalLinkService'
import { globalLabelAsString } from '../services/StoreService'

type TDataLayerParams<T> = T extends IBaseParams ? T : never

let useQueue = true
let queue : Array<Function | TDataLayerParams<IBaseParams>> = []

export const checkDataLayer = () => {
  if (window.dataLayer) {
    useQueue = false
    queue.forEach((el) => window.dataLayer.push(el))
    queue = []
  } else {
    setTimeout(() => {
      checkDataLayer()
    }, 500)
  }
}

const collectPath = (path : string[], element ?: HTMLElement | null) : string => {
  if (element === null || element === undefined) return [...path].reverse().join('.')

  const newPath = [...path]
  const name = element.dataset.tName
  if (name) newPath.push(name)
  return collectPath(newPath, element.parentElement)
}

const collectBreadcrumbs = () : INavLevelParams => {
  // the first entry is the global label for homepage, that should be ignored
  const [, one, two, three, four, five] = StoreSingleton.instance.getters['Breadcrumbs/crumbs']
  return {
    nav_lvl_1: one?.label || globalLabelAsString(one?.globalLabel) || '',
    nav_lvl_2: two?.label || globalLabelAsString(two?.globalLabel) || '',
    nav_lvl_3: three?.label || globalLabelAsString(three?.globalLabel) || '',
    nav_lvl_4: four?.label || globalLabelAsString(four?.globalLabel) || '',
    nav_lvl_5: five?.label || globalLabelAsString(five?.globalLabel) || '',
  }
}

const dataLayerPush = <T>(data : TDataLayerParams<T>) : void => {
  // function keyword is necessary because of 'this'
  // the strange syntax with this : IDataLayer comes from TypeScript
  function reset (this : IDataLayer) {
    this.reset?.()
  }

  if (useQueue) {
    queue.push(reset)
    queue.push(data)
  } else {
    window.dataLayer?.push(reset)
    window.dataLayer?.push(data)
  }
}

const track = <T>(data : TDataLayerParams<T>) : void => {
  if (!data.event) {
    throw TypeError(`[TRACK] Event type was not set correctly. Should be of type string with length >="
    + "1. Was: ${data.event} (type: ${typeof data.event})`)
  }

  dataLayerPush(data)
}

const trackClick = <T>(element : HTMLElement, data : T) : void => {
  track({
    ...data,
    event: 'custom_click',
    comp_path: collectPath([], element),
  })
}

export const trackPI = (pageTitle : string) => {
  track({
    event: 'custom_page_view',
    page_location: document?.location?.href || '',
    page_title: pageTitle,
    ...collectBreadcrumbs(),
  })
}

export const trackCta = (element : HTMLElement, data : ICtaParams) : void => {
  const trackData = { ...data }
  if (isExternalURL(trackData.link_url)) trackData.link_type = 'external'
  trackClick(element, trackData)
}

export const trackExternalLink = (element : HTMLElement, data : ILinkBaseParams) : void => {
  const trackData = { ...data } as ILinkExternalParams
  trackData.link_type = 'external'
  trackClick(element, trackData)
}

export const trackMailLink = (element : HTMLElement, data : ILinkBaseParams) : void => {
  const trackData = { ...data } as ILinkMailParams
  trackData.link_type = 'mailto'
  trackClick(element, trackData)
}

export const trackPhoneLink = (element : HTMLElement, data : ILinkBaseParams) : void => {
  const trackData = { ...data } as ILinkPhoneParams
  trackData.link_type = 'phone'
  trackClick(element, trackData)
}

export const trackDownload = (element : HTMLElement, data : ILinkDownloadBaseParams) : void => {
  const trackData = { ...data } as ILinkDownloadParams
  trackData.link_type = 'download'
  trackClick(element, trackData)
}

export const trackDirectionLink = (element : HTMLElement, data : ILinkBaseParams) : void => {
  const trackData = { ...data } as ILinkDirectionParams
  trackData.link_type = 'route'
  trackClick(element, trackData)
}

export const trackFormElementClick = (element : HTMLElement, data : IFormButtonParams | IFormInputParams) : void => {
  const trackData = { ...data }
  trackClick(element, trackData)
}

export const trackLink = (element : HTMLElement, data : ILinkBaseParams) : void => {
  const params : [HTMLElement, ILinkBaseParams] = [element, data]

  if (data.link_url.startsWith('https://www.google.com/maps/') || data.link_url.startsWith('https://map.baidu.com/')) {
    trackDirectionLink(...params)
    return
  }

  if (isExternalURL(data.link_url)) {
    trackExternalLink(...params)
    return
  }

  if (data.link_url.startsWith('mailto:')) {
    trackMailLink(...params)
    return
  }

  if (data.link_url.startsWith('tel:')) {
    trackPhoneLink(...params)
  }
}

export const paramTracking = () : Record<string, string> => {
  // get all the url parameters
  const urlParams : URLSearchParams = new URLSearchParams(window.location.search)
  // if we have new url Params the old ones are overwritten
  const paramsSession : Record<string, string> = {}
  let hasUtmParams = false;

  [...urlParams].forEach(([key, value]) => {
    if (key.startsWith('utm_') || key === 'affiliate') {
      // if key exists, it'll be overwritten with the new value
      paramsSession[key] = value
      hasUtmParams = true
    }
  })

  /**
   * Get the landing page url without the tracking parameters
   */
  function getLandingPageUrl () {
    const url = new URL(window.location.href);
    [...urlParams].forEach(([key]) => {
      if (key.startsWith('utm_')) {
        url.searchParams.delete(key)
      }
    })
    return url.toString()
  }

  // if we have new parameters overwrite the old tracking parameters in the session storage
  if (hasUtmParams) {
    paramsSession.landingPage = getLandingPageUrl()
    sessionStorage.setItem('trackingParams', JSON.stringify(paramsSession))
  }

  return paramsSession
}
