import { File, Image } from 'fsxa-api'
import { trackDownload } from '../tracking/TrackingService'

interface IMappedLinks {
  href ?: string
  download ?: string
}

export const getFileExtension = (file ?: File | Image | null) : string => {
  if (!file) return ''
  if (file.type === 'Image') {
    return file.resolutions?.ORIGINAL?.extension || ''
  }
  return file.fileMetaData?.extension ?? ''
}

export const calculateFileSize = (file ?: File | Image | null) : string => {
  if (!file) return ''

  const bytes : number | undefined = file.type === 'Image'
    ? file.resolutions?.ORIGINAL?.fileSize
    : file.fileMetaData?.fileSize

  if (!bytes) return ''

  const sizes : string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const k : number = 1024
  const i : number = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed())} ${sizes[i]}`
}

export const mapLinks = (link ?: File | Image | string) : IMappedLinks | null => {
  if (!link) return null
  if (typeof link === 'string') {
    return { href: link }
  }

  if (link.type === 'File') {
    return { download: link.url }
  }

  return { download: link.resolutions?.ORIGINAL?.url ?? '' }
}

export const download = async (url : string | undefined, element : HTMLElement, label : string = 'NO_LABEL')
  : Promise<void> => {
  if (!url) return

  // TODO: temporary implementation until CORS issue is resolved
  const anchorTag = document.createElement('a')
  // this gets only the file name + extension from the url
  const fileName = url.replace(/^.*[\\/]/, '').replace(/\?.*$/, '')
  anchorTag.href = url
  anchorTag.setAttribute('download', `${fileName}`)
  anchorTag.setAttribute('target', '_blank')

  trackDownload(element, {
    link_url: url,
    link_text: label,
    file_name: fileName,
    file_extension: `.${fileName.split('.')[1]}`,
  })

  document.body.appendChild(anchorTag)
  anchorTag.click()
  anchorTag.remove()

  // TODO: enable this when CORS issue is resolved
  // try {
  //   await fetch(finalURL, {
  //     method: 'GET',
  //     mode: 'no-cors',
  //   })
  //     .then((response) => response.blob())
  //     .then(() => {
  //       const anchorTag = document.createElement('a')
  //       // this gets only the file name + extension from the url
  //       const fileName = finalURL.replace(/^.*[\\/]/, '').replace(/\?.*$/, '')
  //       anchorTag.href = finalURL
  //       anchorTag.setAttribute('download', `${fileName}`)
  //       anchorTag.setAttribute('target', '_blank')
  //
  //       document.body.appendChild(anchorTag)
  //
  //       anchorTag.click()
  //       anchorTag.remove()
  //     })
  // } catch (error) {
  //   // eslint-disable-next-line no-console
  //   console.error(error)
  // }
}
