
import { FSXABaseLayout } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'

interface IPayload {
  'pt_title' : string
}

@Component({ name: 'BasePageTitle' })
export default class BasePageTitle extends FSXABaseLayout<IPayload> {
  title : string = ''

  public get pageTitle () : string {
    return this.currentDataset?.data?.tt_title || this.data?.pt_title || this.title || ''
  }
}
