export const buttonSizeLarge = 'min-h-[48px] px-6 py-2 text-base'

export const buttonSizeSmall = 'min-h-[32px] px-4 py-1.5 text-sm'

export const buttonDefaultStyle = 'whitespace-nowrap flex justify-center items-center gap-2 '
  + 'rounded-full appearance-none transition-all duration-300 focus-visible:ring '
  + 'focus-visible:ring-black !outline-none border border-solid font-bold'

export const buttonVariantStyles = {
  default: {
    default: 'bg-darkgreen text-white border-transparent '
      + 'hover:bg-darkgreen-600 '
      + 'active:bg-darkgreen-700 '
      + 'focus-visible:bg-darkgreen',
    disabled: 'bg-gray-50 text-gray-400 border-gray-200',

    inverted: {
      default: 'bg-white text-darkgreen border-transparent '
        + 'hover:bg-darkgreen-50 hover:text-darkgreen-600 '
        + 'active:bg-darkgreen-100 active:text-darkgreen-700 '
        + 'focus-visible:bg-white focus-visible:border-transparent',
      disabled: 'bg-gray-400 text-gray-300 border-gray-300',
    },
  },
  outline: {
    default: 'text-darkgreen border-darkgreen '
      + 'hover:bg-darkgreen-50 hover:border-darkgreen-50 hover:text-darkgreen-600 hover:border-darkgreen-600 '
      + 'active:bg-darkgreen-100 active:border-darkgreen-100 active:text-darkgreen-700 active:border-darkgreen-700 '
      + 'focus-visible:bg-white focus-visible:border-darkgreen-50',
    disabled: 'text-gray-400 border-gray-300',

    inverted: {
      default: 'text-white border-white bg-darkgreen bg-opacity-0 '
        + 'hover:bg-opacity-[0.1] '
        + 'active:bg-opacity-[0.2] '
        + 'focus-visible:border-transparent',
      disabled: 'text-gray-400 border-gray-300',
    },
  },
  ghost: {
    default: 'text-darkgreen border-transparent '
      + 'hover:bg-darkgreen-50 hover:border-darkgreen-50 hover:text-darkgreen-600 '
      + 'active:bg-darkgreen-100 active:border-darkgreen-100 active:text-darkgreen-700 '
      + 'focus-visible:bg-white focus-visible:border-transparent',
    disabled: 'text-gray-400 border-transparent',

    inverted: {
      default: 'text-white border-transparent bg-darkgreen bg-opacity-0 '
        + 'hover:bg-opacity-[0.1] '
        + 'active:bg-opacity-[0.2] '
        + 'focus-visible:border-transparent',
      disabled: 'text-gray-400 border-gray-300',
    },
  },
  neutral: {
    default: 'bg-black text-white border-transparent '
      + 'hover:bg-gray-900 '
      + 'active:bg-gray-800 '
      + 'focus-visible:bg-black',
    disabled: 'bg-gray-50 text-gray-400 border-gray-200',

    inverted: {
      default: 'bg-black text-white border-transparent '
        + 'hover:bg-gray-900 '
        + 'active:bg-gray-800 '
        + 'focus-visible:bg-black',
      disabled: 'bg-gray-50 text-gray-400 border-gray-200',
    },
  },
  neutralOutline: {
    default: 'text-black border-black bg-black bg-opacity-0 '
      + 'hover:bg-opacity-[0.1] '
      + 'active:bg-opacity-[0.2] '
      + 'focus-visible:bg-white',
    disabled: 'text-gray-400 border-gray-300',

    inverted: {
      default: 'text-black border-black bg-black bg-opacity-0 '
        + 'hover:bg-opacity-[0.1] '
        + 'active:bg-opacity-[0.2] '
        + 'focus-visible:bg-white',
      disabled: 'text-gray-400 border-gray-300',
    },
  },
  neutralGhost: {
    default: 'text-black border-transparent bg-black bg-opacity-0 '
      + 'hover:bg-opacity-[0.1] '
      + 'active:bg-opacity-[0.2] '
      + 'focus-visible:bg-transparent',
    disabled: 'text-gray-400 border-gray-200',

    inverted: {
      default: 'text-black border-transparent bg-black bg-opacity-0 '
        + 'hover:bg-opacity-[0.1] '
        + 'active:bg-opacity-[0.2] '
        + 'focus-visible:bg-transparent',
      disabled: 'text-gray-400 border-gray-200',
    },
  },
  accent: {
    default: 'text-darkgreen-800 border-transparent bg-brightgreen-200 '
      + 'hover:bg-brightgreen-300 '
      + 'active:bg-brightgreen-400 '
      + 'focus-visible:bg-brightgreen-200',
    disabled: 'text-gray-700 border-transparent bg-gray-50',

    inverted: {
      default: '',
      disabled: '',
    },
  },
}
