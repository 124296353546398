
import { FSXABaseLayout } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'

interface IPayload {
  pt_shared_content_remote ?: TRemoteDatasetIndex
}

@Component({ name: 'BaseSharedContentPage' })
export default class BaseSharedContentPage extends FSXABaseLayout<IPayload> {}
