
import { FSXABaseRichTextElement } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BlockContent from '../../richtext/BlockContent.vue'

@Component({
  name: 'Block',
  components: { BlockContent },
})
export default class Block extends FSXABaseRichTextElement {}
