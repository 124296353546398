
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface IContent {
  content : string
}

interface IPayload {
  st_author ?: string
  st_statement : [
    {
      content : IContent[]
    }
  ]
}

@Component({
  name: 'StStatement',
  components: {
    BaseGridLayout,
    Statement: () => import('../../Statement.vue'),
  },
})
export default class StStatement extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get author () : string | undefined {
    return this.payload.st_author
  }

  private get content () : string[] {
    const statements = this.payload.st_statement || []
    return statements.flatMap((statement) => statement.content.map((child) => child.content))
  }
}
