import { ActionPayload } from 'vuex'
import { RichTextElement } from 'fsxa-api'
import StoreSingleton from './StoreSingleton'

export interface IAction {
  name : string
  callback : Function
}

export const subscribeAction = (action : IAction) : void => {
  const store = StoreSingleton.instance
  store.subscribeAction((actionPayload : ActionPayload) => {
    if (actionPayload.type !== action.name) return
    action.callback(actionPayload.payload)
  })
}

export const subscribeActions = (actions : IAction[]) : void => {
  if (!actions.length) return

  const store = StoreSingleton.instance
  store.subscribeAction((actionPayload : ActionPayload) => {
    actions.filter((action) => action.name === actionPayload.type)
      .forEach((action) => action.callback(actionPayload.payload))
  })
}

export const globalLabel = (name : string) : string | RichTextElement[] => {
  const locale = StoreSingleton.instance.state.Locale.fsxaLocale
  return StoreSingleton.instance.getters['GlobalLabels/label'](locale, name)
}

const extractText = (data : RichTextElement | RichTextElement[]) : string => {
  if (Array.isArray(data)) {
    return data.map(extractText).join('')
  } if (typeof data === 'object') {
    if (data.content) {
      if (typeof data.content === 'string') {
        return data.content
      }
      return extractText(data.content)
    }
  } else if (typeof data === 'string') {
    return data
  }
  return ''
}

export const globalLabelAsString = (name : string) : string => {
  const locale = StoreSingleton.instance.state.Locale.fsxaLocale
  const label = StoreSingleton.instance.getters['GlobalLabels/label'](locale, name)
  if (typeof label === 'string') return label
  return extractText(label)
}

export const langToFsxaLocale = (lang : string) : string => StoreSingleton.instance.getters['Locales/fsxaLocaleFromLang'](lang)
