
import { Component } from 'nuxt-property-decorator'
import BaseContentProjection from '../../base/BaseContentProjection.vue'
import ContentProjection from '../../../ContentProjection.vue'

@Component({
  name: 'TtEvent',
  components: { ContentProjection },
})
export default class TtEvent extends BaseContentProjection {
  beforeCreate () {
    this.type = 'event'
  }
}
