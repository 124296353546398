
import { Component, Prop, Vue } from 'nuxt-property-decorator'

export type THeadlineFormat = 'h1' | 'h2' | 'h3'

export interface IHeadlineData {
  type ?: THeadlineFormat
  text ?: string
  isSemantic ?: boolean
  bold ?: boolean
}

@Component({ name: 'BaseHeadline' })
export default class BaseHeadline extends Vue {
  @Prop({ default: 'h3' }) type! : THeadlineFormat

  @Prop({ default: '' }) text! : string

  @Prop({ default: false }) isSemantic! : boolean

  @Prop({ default: true }) bold! : boolean

  private defaultType = 'header'

  private get componentType () : string {
    return this.isSemantic ? this.type : this.defaultType
  }

  private get styles () : string {
    switch (this.type) {
      case 'h1':
        return this.bold
          ? 'mb-6 text-2xl sm:text-4xl lg:text-5xl font-bold text-darkgreen'
          : 'mb-6 text-4xl lg:text-5xl italic text-darkgreen'
      case 'h2':
        return this.bold
          ? 'mb-4 text-xl sm:text-2xl md:text-3xl font-bold'
          : 'mb-4 text-xl sm:text-2xl md:text-3xl'
      case 'h3':
        return this.bold
          ? 'mb-4 text-xl font-bold'
          : 'mb-4 text-xl'
      default:
        return 'mb-4 text-xl font-bold'
    }
  }
}
