
import { Component, InjectReactive } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { ProjectProperties } from 'fsxa-api'

@Component({
  name: 'StLocationTeaser',
  components: {
    StTeaserImageText: () => import('./StTeaserImageText.vue'),
  },
})
export default class StLocationTeaser extends FSXABaseSection {
  @InjectReactive({ from: 'globalSettings' }) gs! : ProjectProperties | null

  private get teaserData () {
    return this.gs?.data?.ps_country_project_settings?.pt_location_teaser
  }
}
