
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import EButtonVariant from '../../shared/general/enums/EButtonVariant'
import ELinkTarget from '../../shared/general/enums/ELinkTarget'
import { globalLabelAsString } from '../../shared/general/services/StoreService'

const buttonDefaultStyle = 'whitespace-nowrap flex justify-center items-center gap-1 h-12 '
            + 'rounded-full appearance-none transition-all duration-300 focus-visible:ring '
            + 'focus-visible:ring-black !outline-none border border-solid'

const buttonVariantStyles = {
  default: {
    default: 'bg-black text-white border-transparent',
    disabled: 'bg-gray-50 text-gray-700 border-transparent',

    inverted: {
      default: 'bg-white border-black '
                  + 'hover:bg-gray-50 '
                  + 'active:bg-gray-100 '
                  + 'focus:bg-white focus:border-gray-100',
      disabled: 'bg-gray-50 text-gray-700 border-transparent',
    },
  },
  outline: {
    default: 'bg-black border-black text-black bg-opacity-0 '
                + 'hover:bg-opacity-[0.1] '
                + 'active:bg-opacity-[0.2] '
                + 'focus:bg-opacity-0',
    disabled: 'bg-transparent text-gray-700 border-gray-700',

    inverted: {
      default: 'text-white border-white bg-white bg-opacity-0 '
                  + 'hover:bg-opacity-[0.1] '
                  + 'active:bg-opacity-[0.2] '
                  + 'focus:bg-opacity-0',
      disabled: 'bg-transparent text-gray-700 border-gray-700',
    },
  },
  ghost: {
    default: 'text-black bg-black border-transparent bg-opacity-0 '
                + 'hover:bg-opacity-[0.1] '
                + 'active:bg-opacity-[0.2] '
                + 'focus:bg-opacity-0',
    disabled: 'text-gray-700 bg-transparent border-transparent',

    inverted: {
      default: 'text-white bg-transparent border-transparent bg-opacity-0 '
                + 'hover:bg-opacity-[0.1] '
                + 'active:bg-opacity-[0.2] '
                + 'focus:bg-opacity-0',
      disabled: 'text-gray-407000 bg-transparent border-transparent',
    },
  },
  accent: {
    default: 'text-darkgreen-800 border-transparent bg-brightgreen-200 '
      + 'hover:bg-brightgreen-300 '
      + 'active:bg-brightgreen-400 '
      + 'focus-visible:bg-brightgreen-200',
    disabled: 'text-gray-700 border-transparent bg-gray-50',

    inverted: {
      default: '',
      disabled: '',
    },
  },
}

@Component({
  name: 'BaseIconButton',
  components: { BaseIcon: () => import('./BaseIcon.vue') },
})
export default class BaseIconButton extends Vue {
  @Prop({ default: '' }) link! : string

  @Prop({ default: '' }) target! : ELinkTarget

  @Prop({ required: true }) ariaGlobalLabelKey! : string

  @Prop({ default: '' }) icon! : string

  @Prop({ default: 'light' }) iconType! : string

  @Prop({ default: false }) disabled! : boolean

  @Prop({ default: EButtonVariant.Default }) variant! : EButtonVariant

  @Prop({ default: false }) inverted! : boolean

  @Prop({ default: 'large' }) size! : 'small' | 'large'

  private get style () : string {
    const state = this.disabled ? 'disabled' : 'default'

    if (this.inverted) return `${buttonDefaultStyle} ${buttonVariantStyles[this.variant].inverted[state]}`
    return `${buttonDefaultStyle} ${buttonVariantStyles[this.variant][state]}`
  }

  private get buttonSize () : string {
    return this.size === 'small' ? 'text-base h-8 w-8' : 'text-2xl h-12 w-12'
  }

  private get iconSize () : string {
    return this.size === 'large' ? 'h-6' : 'h-4'
  }

  private get ariaGlobalLabel () : string {
    return globalLabelAsString(this.ariaGlobalLabelKey)
  }

  // Can return 'false', so target attribute won't be rendered when there's no link
  private get getTarget () : ELinkTarget | false {
    if (!this.link) return false
    return this.target
  }

  @Emit('click')
  private click (event : Event) : Event {
    return event
  }
}
