import ELinkTarget from '../../general/enums/ELinkTarget'
import { ILink } from '../../general/interfaces/ILink'
import EButtonVariant from '../../general/enums/EButtonVariant'
import { calculateFileSize, getFileExtension } from '../../general/services/FileService'
import { formatToCleanParam } from '../../general/services/AnchorLinkService'

const getTarget = (target ?: boolean) => (target ? ELinkTarget.Blank : ELinkTarget.Self)
const getValue = (element ?: any) => (typeof element === 'string' ? element || '' : element?.value || '')
const getGlobalLabelKey = (data ?: any) => (data?.lt_link_type?.key)
const getFilenameFromURL = (url : string) : string => /[^/]+(?=\.[^/]+$)/.exec(url)?.[0] || ''

const getIcon = (type ?: string) => {
  if (!type) return ''

  const exceptions = {
    wechat: 'weixin',
    'linkedin-in': 'linkedin',
  }

  return exceptions[type] || type
}

const formatHashParam = (params ?: string) : string => {
  if (!params) return ''
  // link params can include either hash #something or GET Params ?we=are&get=params
  // hash params have to always be at the end of the url, because browsers see everything after # as one hash parameter
  // so we just assume everything after # is supposed to be the hash param for anchorlinks
  // if the two are swapped the GET params wouldn't work either way, so we have other problems
  const hashParam = params.substring(params.indexOf('#') + 1) || ''
  return `#${formatToCleanParam(hashParam)}`
}

export default function getLinkObject (link : any, getUrlByPageId : Function) : ILink {
  if (!link || !link.data) return {}

  const { data } = link
  const templateUID = link.template || link.sectionType || ''
  let linkObject = {} as ILink

  const params = formatHashParam(data.lt_link_params)

  if (templateUID.includes('app_store')) {
    return {
      url: `${getValue(data.lt_link)}${params}`,
      target: getTarget(data.lt_target),
      type: 'appStore',
    }
  }

  if (templateUID.includes('google_play')) {
    return {
      url: `${getValue(data.lt_link)}${params}`,
      target: getTarget(data.lt_target),
      type: 'playStore',
    }
  }

  if (templateUID.includes('mailto')) {
    if (templateUID.includes('with_icon')) {
      linkObject = {
        iconName: 'envelope',
        iconType: 'light',
      }
    }

    return {
      ...linkObject,
      url: `mailto:${data.lt_mail}`,
      label: data.lt_text || data.lt_mail || '',
      iconName: 'envelope',
      iconType: 'light',
      reversed: true,
    }
  }

  if (templateUID.includes('phone')) {
    return {
      url: `tel:${data.lt_phone}`,
      label: data.lt_text || data.lt_phone || '',
      iconName: 'phone-flip',
      iconType: 'light',
      reversed: true,
    }
  }

  if (templateUID.includes('lt_social_media_link')) {
    let url = `${getValue(data.lt_link)}${params}`
    if (data.lt_whatsapp_phone_number) url = `https://wa.me/${data.lt_whatsapp_phone_number}`

    return {
      url,
      target: getTarget(data.lt_target),
      iconName: getIcon(data.lt_social_media_type?.key?.toLowerCase()),
      type: 'social',
      iconType: 'brands',
      class: 'text-4xl',
    } as ILink
  }

  if (templateUID.includes('lt_contact_social_media_link')) {
    return {
      url: `${getValue(data.lt_link)}${params}`,
      target: getTarget(data.lt_target),
      label: data.lt_social_media_type?.value || '',
      socialType: data.lt_social_media_type?.key?.toLowerCase(),
      type: 'social',
    }
  }

  if (templateUID.includes('location')) {
    return {
      url: `${getValue(data.lt_link)}${params}`,
      label: data.lt_link_type?.value || data.lt_link || '',
      iconName: 'arrow-up-right-from-square',
      iconType: 'light',
      globalLabelKey: getGlobalLabelKey(data),
    }
  }

  if (templateUID.includes('ga_optout')) {
    return {
      // eslint-disable-next-line no-script-url
      url: 'javascript:gaOptout()',
      label: data.lt_text || '',
      iconName: 'arrow-up-right-from-square',
      iconType: 'light',
    }
  }

  if (templateUID.includes('matomo_optout')) {
    const optOutMessage = getValue(data.lt_opt_out_message)
    const alreadyOptedOutMessage = getValue(data.lt_already_opted_out_message)

    return {
      url: `javascript:matomoOptout('${optOutMessage}', '${alreadyOptedOutMessage}')`,
      label: data.lt_text || '',
      iconName: 'arrow-up-right-from-square',
      iconType: 'light',
    }
  }

  if (templateUID.includes('lt_social_share_bar_link')) {
    return {
      url: `${getValue(data.lt_link)}${params}`,
      target: getTarget(data.lt_target),
      label: data.lt_social_media_type?.value || '',
      iconName: data.lt_social_media_type?.key?.toLowerCase(),
      iconType: 'light',
      type: 'social',
    }
  }

  if (templateUID.includes('white')) {
    linkObject = {
      ...linkObject,
      buttonInverted: true,
    }
  }

  if (templateUID.includes('download')) {
    if (!data.lt_link) return {}

    const size = calculateFileSize(data.lt_link)
    const extension = getFileExtension(data.lt_link)

    linkObject = {
      ...linkObject,
      url: getValue(
        data.lt_link.resolutions?.ORIGINAL?.url
        || data.lt_link.url
        || undefined,
      ),
      target: getTarget(data.lt_target),
      label:
        data.lt_text
        || data.lt_title_text
        || data.lt_link?.fileName
        || getFilenameFromURL(data.lt_link?.resolutions?.ORIGINAL?.url)
        || '',
      iconName: 'arrow-down-to-line',
      iconType: 'light',
      info: `(${[extension.toUpperCase(), size].join(', ')})`,
      type: 'button',
      reversed: true,
    }
  }

  if (templateUID.includes('download') && templateUID.includes('btn')) {
    return {
      ...linkObject,
      type: 'button',
    }
  }

  if (templateUID.includes('download') && !templateUID.includes('btn')) {
    return {
      ...linkObject,
      type: 'download',
    }
  }

  if (templateUID.includes('btn') || templateUID.includes('job_offer')) {
    linkObject = {
      ...linkObject,
      type: 'button',
      inline: false,
    }
  }

  if (templateUID.includes('job_offer')) {
    linkObject = {
      ...linkObject,
      buttonVariant: EButtonVariant.Outline,
    }
  }

  if (templateUID.includes('external') || templateUID.includes('internal')) {
    linkObject = {
      ...linkObject,
      target: getTarget(data.lt_target),
      title: data.lt_title_text || '',
      label: data.lt_text || data.lt_link_text || '',
      iconType: 'light',
      globalLabelKey: getGlobalLabelKey(data),
    }
  }

  if (templateUID.includes('external')) {
    let iconName = 'arrow-up-right-from-square'
    if (templateUID === 'lt_link_external_stand_alone' && data.lt_icon?.identifier?.includes('email')) {
      iconName = 'envelope'
    }

    linkObject = {
      ...linkObject,
      url: `${getValue(data.lt_link)}${params}`,
      iconName,
    }
  }

  if (templateUID.includes('internal')) {
    let withoutIcon = false
    if (templateUID === 'lt_link_internal_domtable') {
      withoutIcon = data.lt_read_more || false
    }

    linkObject = {
      ...linkObject,
      url: `${getUrlByPageId(data.lt_link?.referenceId || '')}${params}`,
      iconName: 'chevron-right',
      withoutIcon,
    }
  }

  return linkObject
}
