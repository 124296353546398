
import {
  Component, InjectReactive, Prop, ProvideReactive, Watch,
} from 'nuxt-property-decorator'
import { ProjectProperties } from 'fsxa-api'
import Vue from 'vue'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import ILocationData, {
  ILocationTypeData,
  ILocationTypeFilterData,
} from '../../shared/fsxa/interfaces/ILocationData'
import { ILocationAddressObject, ResultState } from './LocationSearch.vue'
import IContactElement from '../../shared/general/interfaces/IContactElement'
import createContactArray from '../../shared/fsxa/services/ContactView'
import { ILocation } from './LocationCard.vue'
import { globalLabel, globalLabelAsString } from '../../shared/general/services/StoreService'
import { setOne } from '../../shared/general/services/privacy/PrivacySettingsService'
import { Logger } from '../../shared/general/logger/Logger'
import IDropdownOption from '../../shared/general/interfaces/IDropdownOption'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../shared/fsxa/types/TRemoteDataset'
import fsxaProxyApiRemote from '../../shared/fsxa/services/FsxaProxyApiRemote'
import { TLocationSearchView } from '../../shared/fsxa/types/TView'

interface ISearchCriteria {
  bounds : {
    south : number
    north : number
    west : number
    east : number
  }
  locationType : ILocationTypeFilterData
  commercialVehicle : boolean
}

@Component({
  name: 'LocationSearchWrapper',
  components: {
    BaseGridLayout,
    LocationSearch: () => import('./LocationSearch.vue'),
    MapsReplacer: () => import('../MapsReplacer.vue'),
    CookieLayer: () => import('../CookieLayer.vue'),
    LocationDetailView: () => import('./LocationDetailView.vue'),
  },
})
export default class LocationSearchWrapper extends Vue {
  @ProvideReactive('contained') isContained : boolean = false

  @InjectReactive({ from: 'globalSettings' }) globalSettings! : ProjectProperties | null

  @InjectReactive({ from: 'getUrlByPageId' }) getUrlByPageId! : Function

  @Prop() view ?: TLocationSearchView

  @Prop({ default: () => [] }) locationTypes! : ILocationTypeData[]

  @Prop({ default: () => [] }) preFilters! : ILocationTypeFilterData[]

  @Prop({ required: true }) id! : string

  @Prop({ default: false }) contained! : boolean

  @Prop({ default: true }) showLocationsWithoutType! : boolean

  @Prop() locationTypeForCommercialVehicles ?: string

  @Prop({ default: () => [] }) tagFilters! : string[]

  private showDetailView : boolean = true

  private savedLocationId : string = ''

  private locationData : ILocationData[] = []

  private locations : IContactElement[][] = [[]]

  private detailView : IContactElement[][] = [[]]

  private detailViewContent : ILocationAddressObject | false = false

  private addresses : ILocation[] = []

  private hasResults : ResultState = ResultState.NONE

  private boundaries = {
    latFrom: 48.8599,
    latTo: 52.4600,
    lngFrom: 6.7737,
    lngTo: 9.2800,
  }

  created () {
    this.isContained = this.contained
  }

  async mounted () {
    await this.fetchRemoteDatasets()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  private async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.countryRemoteDatasetIndex)
  }

  private get preSelectedDropdownOption () : IDropdownOption | undefined {
    if (this.preFilters.length !== 1) return undefined
    // they are basically the same type but using IDropdownOption as name in LocationService would be strange
    return {
      id: this.preFilters[0].id,
      label: this.preFilters[0].label,
      value: this.preFilters[0].value,
    }
  }

  private get googleMapsApiKey () : string {
    return this.globalSettings?.data.ps_google_maps_api_key || ''
  }

  private get countryRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.globalSettings?.data?.ps_country_remote
  }

  private get countryCodeRemote () : string | undefined {
    return getRemoteDatasetsFromStore(this.countryRemoteDatasetIndex)[0]?.data?.tt_code?.toLowerCase()
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get cookiesAccepted () : boolean {
    return this.$store.state.PrivacySettings.settings.google_maps
  }

  private get backToSearchLabel () : string {
    return globalLabelAsString('back_to_search_label')
  }

  private get noVideoAcceptLabel () : string {
    return globalLabelAsString('accept_cookies')
  }

  private async getSavedLocation () : Promise<string> {
    return this.$store.dispatch('Locations/getSavedLocationId', this.$store.state.ToolbarElements.locationSidebarType?.value)
  }

  private async search (searchCriteria ?: ISearchCriteria) : Promise<void> {
    if (searchCriteria) {
      this.boundaries = {
        latFrom: searchCriteria?.bounds?.south,
        latTo: searchCriteria?.bounds?.north,
        lngFrom: searchCriteria?.bounds?.west,
        lngTo: searchCriteria?.bounds?.east,
      }
    }

    const locationTypeIds : string[] = searchCriteria?.locationType
      ? [searchCriteria.locationType.id].filter((id) => !!id) as string[]
      : this.preFilters.map((el) => el.id).filter((id) => !!id) as string[]

    const locationRequest = await fetch('/api/locations/fromBoundaries', {
      method: 'POST',
      body: JSON.stringify({
        locale: this.$store.state.Locale.fsxaLocale,
        boundaries: this.boundaries,
        types: locationTypeIds,
        commercialVehicles: searchCriteria?.commercialVehicle,
      }),
    })

    this.locationData = await locationRequest.json()

    if (!this.showLocationsWithoutType) {
      this.locationData = this.locationData.filter((locationData) => locationData.data?.tt_location_types?.length)
    }

    if (this.tagFilters.length) {
      this.locationData = this.locationData.filter(
        (locationData) => this.tagFilters.every((filterTag) => locationData.data?.tt_tags?.some((tag) => tag.data?.tt_name === filterTag)),
      )
    }

    this.locations = this.locationData.map((location) => createContactArray(
      location,
      this.view?.data?.tt_config_result || [],
      this.getUrlByPageId,
    ))
    this.detailView = this.locationData.map((location) => createContactArray(
      location,
      this.view?.data?.tt_config_detail || [],
      this.getUrlByPageId,
    ))
    this.addresses = this.locationData.map((location) => ({
      id: location.id,
      latitude: location.data?.tt_latitude || 0,
      longitude: location.data?.tt_longitude || 0,
    }))
    this.hasResults = ResultState.TRUE

    if (this.locationData.length === 0) {
      this.hasResults = ResultState.FALSE
    }
  }

  private async saveOrDeleteLocation (locationId : string, locationTypes : string[], actionType : 'save' | 'delete') : Promise<void> {
    if (!locationTypes.length) return

    const actionString : string = actionType === 'delete' ? 'Locations/deleteLocation' : 'Locations/saveLocation'
    const payload : Function = (locationType : string) => (actionType === 'delete' ? locationType : { locationId, locationType })

    const locationActions = await Promise.all(
      locationTypes.map((locationType) => this.$store.dispatch(actionString, payload(locationType))),
    )

    if (locationActions.every((action) => action)) {
      this.$store.commit('Snackbar/set', globalLabel(
        actionType === 'delete' ? 'snackbar_location_removed_label' : 'snackbar_location_saved_label',
      ))
    }
  }

  private async saveBookmark (locationId : string) : Promise<void> {
    try {
      const locationType : string = this.$store.state.ToolbarElements.locationSidebarType?.value
      const locationTypes : string[] = (await fsxaProxyApiRemote.fetchElement({
        id: locationId,
        locale: this.$store.state.Locale.fsxaLocale,
      }))?.data?.tt_location_types?.map((type : ILocationTypeData) => type?.data?.tt_key || '').filter(Boolean) || []

      if (await this.$store.dispatch('Locations/getSavedLocationId', locationType) === locationId) {
        await this.saveOrDeleteLocation(locationId, locationTypes, 'delete')
      } else {
        await this.saveOrDeleteLocation(locationId, locationTypes, 'save')
      }
    } catch (error) {
      Logger.warn(error)
    }
  }

  private async detailsViewContent () : Promise<void> {
    if (!this.savedLocationId) return

    const location = await fsxaProxyApiRemote.fetchElement({
      id: this.savedLocationId,
      locale: this.$store.state.Locale.fsxaLocale,
    })

    const detailData = [location].map((loca) => createContactArray(
      loca,
      this.view?.data?.tt_config_detail || [],
      this.getUrlByPageId,
    ))

    const addressData = [location].map((loca) => ({
      id: loca.id,
      latitude: loca.data?.tt_latitude || 0,
      longitude: loca.data?.tt_longitude || 0,
    }))

    this.detailViewContent = {
      location: [],
      detail: detailData[0],
      address: addressData[0],
    }
  }

  // show the detail view again if the sidebar is closed
@Watch('$store.state.Sidebar.open', { immediate: true })
  private async sidebarOpenStateChange () : Promise<void> {
    this.showDetailView = true
    this.savedLocationId = await this.getSavedLocation()
    if (this.savedLocationId) this.detailsViewContent()
  }

private acceptCookies () : void {
  setOne('google_maps', true)
}

private closeSidebar () : void {
  this.$store.commit('Sidebar/set', false)
}

private closeDetailView () : void {
  this.showDetailView = false
}
}
