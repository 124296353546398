
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseHeadline, { IHeadlineData } from '../base/BaseHeadline.vue'

@Component({
  name: 'BlockContent',
  components: { BaseHeadline },
})
export default class BlockContent extends Vue {
  @Prop({ required: true }) data! : Record<string, string>

  private get showAsHeadline () : boolean {
    return this.data['data-fs-style'] !== 'format.strong'
  }

  private get headlineProps () : IHeadlineData {
    return {
      isSemantic: ['format.h2', 'format.h3'].includes(this.data['data-fs-style']),
      type: this.data['data-fs-style'] === 'format.h2' ? 'h2' : 'h3',
    }
  }
}
