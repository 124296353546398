
import { Dataset } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import ELinkTarget from '../../../shared/general/enums/ELinkTarget'
import { ILink } from '../../../shared/general/interfaces/ILink'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'

interface IPayload {
  st_social_media_button_linklist : Dataset[]
}

@Component({
  name: 'StSocialMediaButtons',
  components: {
    BaseGridLayout,
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
    SocialMediaButtons: () => import('../../SocialMediaButtons.vue'),
  },
})
export default class StSocialMediaButtons extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string {
    return globalLabelAsString('social_buttons_headline')
  }

  private get buttons () : ILink[] {
    return this.payload.st_social_media_button_linklist?.map((button) => ({
      url: button.data?.lt_whatsapp_phone_number
        ? `https://wa.me/${button.data?.lt_whatsapp_phone_number}`
        : button.data?.lt_link,
      target: button.data?.lt_target ? ELinkTarget.Blank : ELinkTarget.Self,
      socialType: button.data?.lt_social_media_type?.key,
      type: 'social-buttons',
    } as ILink)) || []
  }
}
