
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import IReference from '../../../shared/fsxa/interfaces/IReference'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { ILink } from '../../../shared/general/interfaces/ILink'

interface IPayload {
  st_link_list : {
    data : {
      lt_link ?: IReference | string
      lt_text ?: string
      lt_target ?: boolean
    }
  }[]
  st_headline ?: string
}

@Component({
  name: 'StLinkList',
  components: {
    BaseGridLayout,
    BaseList: () => import('../../base/BaseList.vue'),
  },
})
export default class StLinkList extends FSXABaseSection<IPayload> {
  private get header () {
    return this.payload.st_headline
  }

  private get items () : ILink[] {
    return this.payload.st_link_list?.map((linkElement) => getLinkObject(linkElement, this.getUrlByPageId) || {})
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
