
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'

interface IPayload {
  st_headline ?: string
  st_anchor_text ?: string
  st_show_separation_line ?: boolean
}

@Component({
  name: 'StSeperationLineAnchor',
  components: {
    SeparationLineAnchor: () => import('../../SeparationLineAnchor.vue'),
  },
})
export default class StSeperationLineAnchor extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string {
    return this.payload.st_headline || ''
  }

  private get showSeparationLine () : boolean | undefined {
    return this.payload.st_show_separation_line
  }

  private get anchorText () : string | undefined {
    return this.payload.st_anchor_text
  }
}
