/* eslint-disable max-len */
export default [
  'Eminr;PKW_Benziner;PKW_Diesel_ohne_PM;PKW_Diesel_PM01;PKW_Diesel_PM0;PKW_Diesel_PM1;PKW_Diesel_PM2;PKW_Diesel_PM3;PKW_Diesel_PM4;PKW_Diesel_PM5;NFZ_Benzin;NFZ_Diesel_ohne_PM;NFZ_Diesel_PM01;NFZ_Diesel_PM0;', 'NFZ_Diesel_PM1;NFZ_Diesel_PM2;NFZ_Diesel_PM3;NFZ_Diesel_PM4;NFZ_Diesel_PM5;Kommentar',
  '|00|;0;0;-1;-1;-1;-1;-1;-1;-1;0;0;-1;-1;-1;-1;-1;-1;-1;Stand 16.04.2018',
  '|01|;4;0;-1;-1;-1;-1;-1;-1;-1;0;0;-1;-1;-1;-1;-1;-1;-1;BA bis AZ erg�nzt',
  '|02|;4;0;-1;-1;-1;-1;-1;-1;-1;0;0;-1;-1;-1;-1;-1;-1;-1;',
  '|03|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|04|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|05|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|06|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|07|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|08|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|09|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|10|;0;0;-1;-1;-1;-1;-1;-1;-1;0;0;-1;2;3;4;-1;-1;-1;',
  '|11|;0;0;-1;-1;-1;-1;-1;-1;-1;0;0;-1;2;3;4;-1;-1;-1;',
  '|12|;0;0;-1;-1;-1;-1;-1;-1;-1;0;0;-1;2;3;4;-1;-1;-1;',
  '|13|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|14|;4;0;-1;2;3;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|15|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|16|;4;0;-1;2;3;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|17|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|18|;4;0;-1;2;3;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|19|;4;0;2;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|20|;4;0;2;-1;-1;-1;-1;-1;-1;4;2;-1;-1;3;4;4;-1;-1;',
  '|21|;4;0;-1;2;3;-1;-1;-1;-1;4;2;-1;-1;3;4;4;-1;-1;',
  '|22|;4;0;-1;2;3;-1;-1;-1;-1;4;2;-1;-1;3;4;4;-1;-1;',
  '|23|;4;0;2;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|24|;4;0;2;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|25|;4;2;-1;-1;3;4;4;4;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|26|;4;2;-1;-1;3;4;4;4;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|27|;4;2;-1;-1;5;4;4;4;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|28|;4;2;-1;3;4;4;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|29|;4;2;-1;3;4;4;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|30|;4;3;-1;-1;-1;4;4;4;4;4;0;-1;2;3;-1;-1;-1;-1;',
  '|31|;4;3;-1;-1;-1;4;4;4;4;4;0;-1;2;3;-1;-1;-1;-1;',
  '|32|;4;4;-1;-1;-1;-1;4;4;4;4;0;-1;2;3;-1;-1;-1;-1;',
  '|33|;4;4;-1;-1;-1;-1;4;4;4;4;2;-1;-1;3;4;4;4;-1;',
  '|34|;4;0;-1;2;3;-1;-1;-1;-1;4;3;-1;-1;-1;4;4;4;-1;',
  '|35|;4;2;-1;-1;3;4;4;4;4;4;4;-1;-1;-1;-1;4;4;4;',
  '|36|;4;3;-1;-1;-1;4;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|37|;4;3;-1;-1;-1;4;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|38|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|39|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|40|;4;0;-1;2;3;-1;-1;-1;-1;4;0;2;-1;-1;-1;-1;-1;-1;',
  '|41|;4;2;-1;-1;3;4;4;4;-1;4;0;2;-1;-1;-1;-1;-1;-1;',
  '|42|;4;3;-1;-1;-1;4;4;4;4;4;0;2;-1;-1;-1;-1;-1;-1;',
  '|43|;4;4;-1;-1;-1;-1;4;4;4;4;2;-1;3;4;4;-1;-1;-1;',
  '|44|;4;3;-1;-1;-1;4;4;4;4;4;3;-1;-1;4;4;4;4;4;',
  '|45|;4;3;-1;-1;-1;4;4;4;4;4;4;-1;-1;-1;4;4;4;4;',
  '|46|;4;3;-1;-1;-1;4;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|47|;4;3;-1;-1;-1;4;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|48|;4;3;-1;-1;-1;4;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|49|;4;3;-1;-1;4;4;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|50|;4;3;-1;-1;4;4;4;4;4;4;0;2;-1;-1;-1;-1;-1;-1;',
  '|51|;4;3;-1;-1;4;4;4;4;4;4;0;2;-1;-1;-1;-1;-1;-1;',
  '|52|;4;3;-1;-1;4;4;4;4;4;4;0;2;-1;-1;-1;-1;-1;-1;',
  '|53|;4;4;-1;-1;-1;-1;4;4;4;4;2;-1;3;4;4;-1;-1;-1;',
  '|54|;4;4;-1;-1;-1;-1;4;4;4;4;3;-1;-1;4;4;4;4;4;',
  '|55|;4;4;-1;-1;-1;-1;4;4;4;4;4;-1;-1;-1;4;4;4;4;',
  '|56|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|57|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|58|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|59|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|60|;4;4;-1;-1;-1;-1;4;4;4;4;2;-1;-1;3;4;4;-1;-1;',
  '|61|;4;4;-1;-1;-1;-1;4;4;4;4;2;-1;-1;3;4;4;-1;-1;',
  '|62|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;0;0;-5;-5;-5;-5;-5;',
  '|63|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;0;0;-5;-5;-5;-5;-5;',
  '|64|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;0;0;-5;-5;-5;-5;-5;',
  '|65|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;0;0;-5;-5;-5;-5;-5;',
  '|66|;4;4;-1;-1;-1;-1;4;4;4;-5;-5;0;0;-5;-5;-5;-5;-5;',
  '|67|;4;4;-1;-1;-1;4;4;4;4;-5;-5;0;0;-5;-5;-5;-5;-5;',
  '|68|;4;4;-1;-1;-1;4;4;4;4;-5;-5;0;0;-5;-5;-5;-5;-5;',
  '|69|;4;4;-1;-1;-1;4;4;4;4;-5;-5;0;0;-5;-5;-5;-5;-5;',
  '|70|;4;4;-1;-1;-1;4;4;4;4;4;3;-1;-1;-1;4;-1;-1;-1;',
  '|71|;4;2;-1;-1;3;4;4;-1;-1;4;3;-1;-1;-1;4;-1;-1;-1;',
  '|72|;4;3;-1;-1;-1;4;4;4;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|73|;4;4;-1;-1;-1;4;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|74|;4;4;-1;-1;-1;4;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|75|;4;4;-1;-1;-1;-1;4;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|76|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|77|;4;0;0;2;3;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|78|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|79|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|80|;-5;-5;-5;-5;-5;-5;-5;-5;-5;4;4;-1;-1;-1;4;-1;-1;-1;',
  '|81|;-5;-5;-5;-5;-5;-5;-5;-5;-5;4;4;-1;-1;-1;4;-1;-1;-1;',
  '|82|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|83|;-5;-5;-5;-5;-5;-5;-5;-5;-5;4;4;-1;-1;-1;4;-1;-1;-1;',
  '|84|;-5;-5;-5;-5;-5;-5;-5;-5;-5;4;4;-1;-1;-1;4;-1;-1;-1;',
  '|85|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|86|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|87|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|88|;0;0;-1;-1;-1;-1;-1;-1;-1;0;0;-1;-1;-1;-1;-1;-1;-1;',
  '|89|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|90|;-5;-5;-5;-5;-5;-5;-5;-5;-5;4;4;-1;-1;-1;-1;4;-1;-1;',
  '|91|;0;0;-1;-1;-1;-1;-1;-1;-1;4;4;-1;-1;-1;-1;4;-1;-1;',
  '|92|;0;0;-1;-1;-1;-1;-1;-1;-1;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|93|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|94|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|95|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|96|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|97|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|98|;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;-6;',
  '|99|;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;-5;',
  '|A0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|B0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|C0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|D0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|E0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|F0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|G0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|H0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|I0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|J0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|K0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|L0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|M0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|N0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|O0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|P0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|Q0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|R0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|S0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|T0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|U0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|V0|;-3;4;-1;-1;-1;-1;-1;4;4;-3;4;-1;-1;-1;-1;-1;4;4;',
  '|W0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|X0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|Y0|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZX|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZY|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZZ|;4;4;-1;-1;-1;-1;-1;-1;-1;4;4;-1;-1;-1;-1;-1;-1;-1;',
  '|ZA|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZB|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZC|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZD|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZE|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZF|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZG|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZH|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZI|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZJ|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZK|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|ZL|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|BA|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|BB|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|BC|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AA|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AB|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AC|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AD|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AE|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AF|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AG|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AH|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AI|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|BG|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|BH|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|BI|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AJ|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AK|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AL|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AX|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AY|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
  '|AZ|;4;4;-1;-1;-1;-1;-1;4;4;4;4;-1;-1;-1;-1;-1;4;4;',
]
