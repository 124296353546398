
import {
  File, Image, Link, Option,
} from 'fsxa-api'
import { Component } from 'nuxt-property-decorator'
import BaseTeaserRc from '../base/BaseTeaserRc'
import { mapImage } from '../../../shared/general/services/ImageService'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { TMediaType } from '../../../shared/general/types/TMediaType'
import { buildPlayerElements, getVideoFormat } from '../../../shared/general/services/VideoUrlService'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import IOption from '../../../shared/general/interfaces/IOption'

interface IPayload {
  st_box_position ?: boolean
  st_column ?: IOption
  st_headline ?: string
  st_image ?: Image
  st_image_alt_text ?: string
  st_image_decorative ?: boolean
  st_link ?: Link
  st_mp4 ?: File
  st_ogv ?: File
  st_text ?: string
  st_image_video ?: IOption
  st_text_image_video ?: IOption
  st_video_caption ?: string
  st_video_format ?: Option
  st_video_id ?: string
  st_vimeo_privacy_hash ?: string
  st_webm ?: File
}

@Component({
  name: 'StTeaserRcTextImageVideo',
  components: {
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
  },
})
export default class StTeaserRcTextImageVideo extends BaseTeaserRc<IPayload> {
  mounted () {
    super.mounted()
  }

  private get widthClass () : string {
    return this.numberOfColumns === 2
      ? 'w-full sm:w-[calc(100%/2-1.125rem)] md:w-[calc(100%/3-1.125rem)] lg:w-[calc(((100%/4)*2)-1.125rem)]'
      : 'w-full sm:w-[calc(100%/2-1.125rem)] md:w-[calc(100%/3-1.125rem)] lg:w-[calc(100%/4-1.125rem)]'
  }

  private get mediaType () : TMediaType {
    return this.payload.st_column?.identifier === '1'
      ? this.payload.st_text_image_video?.identifier as TMediaType
      : this.payload.st_image_video?.identifier as TMediaType
  }

  private get teaserData () : ITeaserData {
    const link = getLinkObject(this.payload.st_link, this.getUrlByPageId)
    if (link.type === 'download') {
      link.reversed = true
      link.showInfo = true
      link.displayInfoAs = 'row'
    }

    if (this.payload.st_link?.template === 'lt_link_internal_download_domtable') link.url = '#'

    return {
      headline: this.payload.st_headline || '',
      text: this.payload?.st_text || '',
      image: mapImage(this.payload.st_image, this.payload.st_image_alt_text, this.payload.st_image_alt_text, this.payload.st_image_decorative),
      link: this.mediaType === 'video' ? {} : link,
      mediaType: this.mediaType,
      video: {
        id: this.payload.st_video_id || '',
        title: this.payload.st_video_caption,
        format: getVideoFormat(this.payload.st_video_format?.key),
        htmlPlayerElements: buildPlayerElements({
          mp4: this.payload.st_mp4,
          webm: this.payload.st_webm,
          ogv: this.payload.st_ogv,
        }),
        privacyHash: this.payload?.st_vimeo_privacy_hash,
      },
    }
  }
}
