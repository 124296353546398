
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { ILink } from '../../../shared/general/interfaces/ILink'
import getLinkObject from '../../../shared/fsxa/services/LinkService'

@Component({
  name: 'StButtonLink',
  components: {
    ButtonLinkOverview: () => import('../../overview/ButtonLinkOverview.vue'),
    BaseGridLayout,
  },
})
export default class StButtonLink extends FSXABaseSection {
  private get alignment () : string | undefined {
    return this.payload.st_position?.identifier
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get elements () : ILink[] {
    const links = this.payload.st_button_type?.identifier === 'app'
      ? [this.payload.st_app_button, this.payload.st_app_button2]
      : [this.payload.st_link, this.payload.st_link2, this.payload.st_link3]

    return links
      .filter((payload) => !!payload && payload.template)
      .map((payload) => getLinkObject(payload, this.getUrlByPageId) || {})
  }
}
