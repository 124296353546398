
import { FSXABaseRichTextElement } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BaseLink from '../../base/BaseLink.vue'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import { ILink } from '../../../shared/general/interfaces/ILink'

@Component({
  name: 'RichTextLink',
  components: {
    BaseLink,
  },
})
export default class Link extends FSXABaseRichTextElement {
  get link () : ILink | undefined {
    return getLinkObject(this.data, this.getUrlByPageId)
  }
}
