
import { Component, Provide } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { ISmartSearchMediaHost } from '../../../shared/smartsearch/ExtractTeaserImage'

interface IPayload {
  st_headline ?: string
}

@Component({
  name: 'StTeaserNewsMediaOverview',
  components: {
    BaseGridLayout,
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
    TeaserOverview: () => import('../../teaser/overview/TeaserOverview.vue'),
  },
})
export default class StTeaserNewsMediaOverview extends FSXABaseSection<IPayload> {
  @Provide() smartSearchMediaHost : ISmartSearchMediaHost = {
    mediaHost: this.$config.SMART_SEARCH_MEDIA_HOST,
    projectId: this.$config.FSXA_PROJECT_ID,
    remotes: this.$config.FSXA_REMOTES,
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string | undefined {
    return this.payload.st_headline
  }

  private get customParams () : [string, string][] {
    return [
      ['facet.filter.FS_template_uid', 'local_data.tt_article'],
      ['facet.filter.FS_template_uid', 'global_data.tt_article'],
      ['facet.filter.FS_template_uid', 'local_data.tt_press_release'],
      ['facet.filter.FS_template_uid', 'global_data.tt_press_release'],
      ['facet.filter.FS_template_uid', 'global_data.tt_event'],
      ['facet.filter.FS_template_uid', 'local_data.tt_event'],
    ]
  }
}
