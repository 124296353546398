
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'

@Component({
  name: 'StFormElements',
  components: {
    FormElementsOverview: () => import('../../overview/FormElementsOverview.vue'),
  },
})
export default class StFormElements extends FSXABaseSection {
}
