
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection, FSXARichText } from 'fsxa-pattern-library'
import { RichTextElement } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { globalLabel, globalLabelAsString } from '../../../shared/general/services/StoreService'
import { TInputState } from '../../base/form/BaseInput.vue'
import { Logger } from '../../../shared/general/logger/Logger'
import { nuxt } from '../../../shared/general/logger/LogKey'

@Component({
  name: 'StNewslettertogoSubscription',
  components: {
    BaseGridLayout,
    RichText: FSXARichText,
    GlobalLabelWrapper: () => import('../../GlobalLabelWrapper.vue'),
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
    BaseButton: () => import('../../base/BaseButton.vue'),
    BaseInput: () => import('../../base/form/BaseInput.vue'),
    BaseCheckbox: () => import('../../base/form/BaseCheckbox.vue'),
  },
})
export default class StNewslettertogoSubscription extends FSXABaseSection {
  private dataProtectionChecked : boolean = false

  private mailError : TInputState = 'default'

  private checkboxError : boolean = false

  private email : string = ''

  private name : string = ''

  private get headline () : string {
    return this.payload.st_headline || ''
  }

  private get text () : string {
    return this.payload.st_continuous_text || ''
  }

  private get checkboxLabel () : FSXARichText | string {
    return this.payload.st_checkbox_labeling || ''
  }

  private get emailLabel () : string {
    return globalLabelAsString('mail_label')
  }

  private get nameLabel () : string {
    return globalLabelAsString('name_optional_label')
  }

  private get subscribeLabel () : string {
    return globalLabelAsString('subscribe_label')
  }

  private get emailErrorLabel () : string | RichTextElement[] {
    return globalLabel('email_error_label')
  }

  private get newsletterId () : string {
    return this.payload.st_newsletter_id || ''
  }

  private async submit () : Promise<void> {
    if (!this.newsletterId) return

    this.mailError = 'default'
    this.checkboxError = false

    if (!this.email) {
      this.mailError = 'error'
    }

    if (!this.dataProtectionChecked) {
      this.checkboxError = true
    }

    if (!this.email || !this.dataProtectionChecked) return

    const bodyObject = {
      captcha: {},
      lists: [],
      recipient: {
        email: this.email,
        last_name: this.name,
      },
    }

    let result
    try {
      result = await fetch(`https://api.newsletter2go.com/forms/submit/${this.newsletterId}?type=subscribe`, {
        method: 'POST',
        body: JSON.stringify(bodyObject),
      }).then((data) => data.json())
    } catch (e) {
      Logger.error(nuxt, 'Could not fetch newsletter2go subscription | StNewsLettertogoSubscription => submit |', e)
      return
    }

    if (result.status === 201) {
      // user added to newsletter
      // Redirect to "user successfully added" page
      const successfulUrl = this.getUrlByPageId(this.payload.st_redirect_succesfull.referenceId)
      if (successfulUrl) this.triggerRouteChange({ route: successfulUrl })
    } else if (result.value?.[0].result.error.recipients.duplicate.length > 0) {
      // Redirect to "user already exists" page
      const duplicateUrl = this.getUrlByPageId(this.payload.st_redirect_duplicate.referenceId)
      if (duplicateUrl) this.triggerRouteChange({ route: duplicateUrl })
    } else if (result.value?.[0].result.error.recipients.invalid.length > 0) {
      // One of the fields of the recipient is invalid.
      // Since only email is validated, it can only be the email that was entered incorrect
      this.mailError = 'error'
    } else if (result.status === 400) {
      // some other error (will be handled with CMS-7633)
    }
  }
}
