/**
 * This is a fake jQuery implementation for Google Tag Manager to work.
 * As GTM only uses some functions of jQuery I wanted to avoid the inclusion of a big library.
 * Also, using jQuery with vue? Seriously? No.
 * If you SOMEHOW will need a full jQuery integration in the future delete this file and use jQ instead.
 * I looked through the minimized GTM script to find out which jQ functions in which varieties are necessary.
 * This should cover everything.
 */

class FakeQueryType {
  elements = []

  length = 0

  constructor (elements) {
    this.elements = elements
    this.length = elements.length
  }

  closest (selector) {
    const recursiveFindElement = (element) => {
      if (!element) return null
      return element.matches(selector) ? element : recursiveFindElement(element.parentElement)
    }

    const found = this.elements.map((element) => recursiveFindElement(element)).filter((e) => !!e)

    return new FakeQueryType(found)
  }

  attr (name, value) {
    if (!name) return this

    if (!!name && !value) {
      return this.elements.map((el) => el.getAttribute(name))
    }

    if (!!name && !!value) {
      this.elements.forEach((el) => (el.setAttribute(name, value)))
    }

    return this
  }

  val () {
    return this.elements[0].value
  }

  html (htmlString) {
    if (htmlString === null || htmlString === undefined) {
      if (this.elements.length) return this.elements[0].innerHTML
      return null
    }

    this.elements.forEach((el) => { el.innerHTML = htmlString })
    return this
  }

  text (text) {
    if (text === null || text === undefined) {
      if (this.elements.length) return this.elements[0].innerText
      return null
    }

    this.elements.forEach((el) => { el.innerText = text })
    return this
  }

  hasClass (className) {
    return this.elements.some((el) => el.classList.contains(className)) || false
  }

  find (selector) {
    return new FakeQueryType(this.elements.map((el) => [...(el.querySelectorAll(selector) || [])]).flat())
  }

  first () {
    return new FakeQueryType(this.elements[0])
  }

  parent () {
    return new FakeQueryType(this.elements.map((el) => el.parentElement))
  }
}

window.$ = (selector) => new FakeQueryType([...(document.querySelectorAll(selector) || [])])
