
import { Component } from 'nuxt-property-decorator'
import BaseContentProjection from '../../base/BaseContentProjection.vue'
import ContentProjection from '../../../ContentProjection.vue'

@Component({
  name: 'TtPressRelease',
  components: { ContentProjection },
})
export default class TtPressRelease extends BaseContentProjection {
  beforeCreate () {
    this.type = 'press_release'
  }
}
