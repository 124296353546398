
import { FSXABaseLayout } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'

interface IPayload {
  'pt_display_social_share_bar' : boolean
}

@Component({ name: 'BasePageSocialSharing' })
export default class BasePageSocialSharing extends FSXABaseLayout<IPayload> {}
