
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IBreadcrumbItem from '../../shared/general/interfaces/IBreadcrumbItem'
import { globalLabelAsString } from '../../shared/general/services/StoreService'

@Component({
  name: 'TheBreadcrumb',
  components: {
    BaseIcon: () => import('../base/BaseIcon.vue'),
    BaseLink: () => import('../base/BaseLink.vue'),
    BaseGridLayout: () => import('./BaseGridLayout.vue'),
  },
})
export default class TheBreadcrumb extends Vue {
  @Prop({ default: () => [] }) items! : IBreadcrumbItem[]

  private getLabel (item : IBreadcrumbItem) : string {
    if (item.globalLabel) return globalLabelAsString(item.globalLabel)

    return item.label || ''
  }

  private hasLink (item : IBreadcrumbItem) : boolean {
    return !!item?.link
  }
}
