
import { Component, Prop } from 'nuxt-property-decorator'
import Vue from 'vue'
import { TIcon } from '../../shared/general/types/TIcon'
import BaseIcon from './BaseIcon.vue'

@Component({
  name: 'BaseIconText',
  components: { BaseIcon },
})
export default class BaseIconText extends Vue {
  @Prop() label ?: string

  @Prop({ required: true }) iconName! : string

  @Prop({ default: 'light' }) type! : TIcon

  @Prop({ default: false }) reversed! : boolean
}
