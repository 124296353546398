
import { File, Image } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { ILink } from '../../../shared/general/interfaces/ILink'
import getLinkObject from '../../../shared/fsxa/services/LinkService'

interface IPayload {
  st_download_list ?: {
    data ?: {
      lt_link ?: File | Image
      lt_target ?: boolean
      lt_text ?: string
    }
  }[]
  st_headline ?: string
}

@Component({
  name: 'StDownloadList',
  components: {
    BaseGridLayout,
    BaseList: () => import('../../base/BaseList.vue'),
  },
})
export default class StDownloadList extends FSXABaseSection<IPayload> {
  private get header () : string {
    return this.payload.st_headline || ''
  }

  private get downloadList () : ILink[] {
    return this.payload.st_download_list
      ?.map((downloadElement) => ({
        ...getLinkObject(downloadElement, this.getUrlByPageId),
        displayInfoAs: 'row-separate',
        showInfo: true,
        infoGroupHover: true,
      } || {})) || []
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
