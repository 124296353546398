import IHeaderNavElement from '../interfaces/IHeaderNavElement'

export const setAllChildrenInactiveRecursively = (nav : IHeaderNavElement) => {
  nav.isActive = false
  nav.children?.forEach(setAllChildrenInactiveRecursively)
}

export const resetActiveState = (entries : IHeaderNavElement[]) : IHeaderNavElement[] => {
  const shallowCopy = [...entries]
  shallowCopy.forEach(setAllChildrenInactiveRecursively)
  return shallowCopy
}

export const removeLastFromTree = (tree : IHeaderNavElement[]) : IHeaderNavElement[] => {
  const [, ...newTree] = [...tree].reverse()
  return newTree.reverse()
}
