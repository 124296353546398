import { Component, Vue } from 'nuxt-property-decorator'
import { CreateElement, VNode } from 'vue'
import LifespanInfo from '../../LifespanInfo.vue'

interface Lifespan {
  start ?: string
  end ?: string
}

interface ComponentProperties {
  meta ?: {
    lifespan ?: Lifespan
  }
}

@Component({
  name: 'LifespanAwareContentWrapper',
  components: { LifespanInfo },
})
export default class LifespanAwareContentWrapper extends Vue {
  render (createElement : CreateElement) : VNode {
    const originalChildren : VNode[] = this.$slots?.default || []

    const actualChildren = this.$config.FSXA_MODE === 'preview'
      ? originalChildren.map((child) => this.enrichLifespan(child, createElement))
      : originalChildren.filter((child) => this.isVisible(child))

    return createElement('section', { staticClass: 'content-wrapper' }, actualChildren)
  }

  private enrichLifespan (node : VNode, createElement : CreateElement) : VNode {
    const { locale } = this.$store.state.Locale
    const lifespan = this.getLifespan(node)

    if (!lifespan) return node

    return createElement(
      'div',
      [
        createElement('LifespanInfo', { props: { ...lifespan, locale } }),
        node,
      ],
    )
  }

  private isVisible (node : VNode) : boolean {
    const lifespan = this.getLifespan(node)
    if (!lifespan) return true

    const { start, end } = lifespan
    const tooEarly = start && new Date() < new Date(start)
    const tooLate = end && new Date() > new Date(end)
    return !tooEarly && !tooLate
  }

  private getLifespan (node : VNode) : Lifespan | undefined {
    const props : ComponentProperties | undefined = node.componentOptions?.propsData
    return props?.meta?.lifespan
  }
}
