import { ComparisonQueryOperatorEnum } from 'fsxa-api'
import { Store } from 'vuex'
import IThirdPartyToolDetailsData from '../../../fsxa/interfaces/IThirdPartyToolDetailsData'
import { getPrivacySettingsKeys, getPrivacySettingsValue } from '../../../../store/PrivacySettings'
import StoreSingleton from '../StoreSingleton'
import fsxaProxyApiRemote from '../../../fsxa/services/FsxaProxyApiRemote'

export interface IStatus {
  key : string
  value : boolean
}

const store = new Proxy<Store<any>>({} as Store<any>, {
  get: (_, prop) => {
    if (prop) {
      return StoreSingleton.instance[prop]
    }
    return StoreSingleton.instance
  },
})

const getAllPrivacySettingsKeys = () : string[] => {
  const vuexKeys : string[] = Object.keys(store.state.PrivacySettings.settings)
  const privacySettingsKeys : string[] = getPrivacySettingsKeys()
  return [...new Set([...vuexKeys, ...privacySettingsKeys])]
}

const statusOne = (key : string) : boolean => store.state.PrivacySettings.settings[key] || getPrivacySettingsValue(key) || false

export const setOne = (key : string, value : boolean) : void => {
  store.commit('PrivacySettings/set', { key, value })
}

export const statusAll = (filterKeys ?: string[]) : IStatus[] => getAllPrivacySettingsKeys()
  .filter((pKey) => filterKeys?.includes(pKey) || true)
  .map((key) => ({ key, value: statusOne(key) }))

export const setAll = (customKeys : string[], value : boolean) : void => {
  const keys : string[] = customKeys || getAllPrivacySettingsKeys()
  keys.forEach((key) => setOne(key, value))
}

export const fetchFilteredPrivacyKeys = async (
  defaultCookies : Record<string, string>[],
  locale : string,
) : Promise<string[]> => {
  try {
    const keys : string[] = [
      ...defaultCookies.map((cookie) => cookie.key),
      'necessary',
    ]

    const { items } = await fsxaProxyApiRemote.fetchByFilter({
      filters: [
        {
          field: 'entityType',
          operator: ComparisonQueryOperatorEnum.EQUALS,
          value: 'third_party_tool_details',
        },
        {
          field: 'schema',
          operator: ComparisonQueryOperatorEnum.EQUALS,
          value: 'global_data',
        },
      ],
      locale,
    })

    return ((items || []) as IThirdPartyToolDetailsData[])
      .filter((item) => !!item && keys.includes(item.data?.tt_category?.key))
      .map((item) => item.data?.tt_key)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return []
  }
}
