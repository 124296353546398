
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IImageVideoContent from '../../shared/general/interfaces/IImageVideoContent'

@Component({
  name: 'ImageVideoModalContent',
  components: {
    BaseImageVideo: () => import('./BaseImageVideo.vue'),
    BaseVideo: () => import('../base/BaseVideo.vue'),
    BaseHtmlPlayer: () => import('../base/BaseHtmlPlayer.vue'),
    BasePicture: () => import('../base/BasePicture.vue'),
  },
})
export default class ImageVideoModalContent extends Vue {
  @Prop({ required: true }) content! : IImageVideoContent

  private closeModal () : void {
    if (this.content.tag !== 'BasePicture') return
    this.$store.commit('ModalContent/reset')
  }
}
