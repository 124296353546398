
import { Component } from 'nuxt-property-decorator'
import BasePage from '../base/BasePage.vue'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import BaseButton from '../../base/BaseButton.vue'
import BaseToolbarWrapper from '../../base/toolbar/BaseToolbarWrapper.vue'
import BaseHeadline from '../../base/BaseHeadline.vue'

@Component({
  name: 'PtJobOfferDetailPage',
  components: {
    BaseButton,
    BaseGridLayout,
    BaseToolbarWrapper,
    BaseHeadline,
  },
})
export default class PtJobOfferDetailPage extends BasePage {}
