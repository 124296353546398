
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { Section } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { IHighlightBulletList } from '../../../shared/general/interfaces/IHighlightBulletList'

interface IPayload {
  st_headline ?: string
  st_headline_format ?: {
    identifier : 'h2' | 'h3' | 'default'
  }
  st_list_items ?: Section[]
}

@Component({
  name: 'StHighlightBulletList',
  components: {
    HighlightBulletList: () => import('../../HighlightBulletList.vue'),
    BaseGridLayout,
  },
})
export default class StHighlightBulletList extends FSXABaseSection<IPayload> {
  private get headlineText () : string {
    return this.payload.st_headline || ''
  }

  private get headlineTag () : 'h2' | 'h3' {
    if (this.payload.st_headline_format?.identifier === 'h2') return 'h2'
    return 'h3'
  }

  private get semanticHeadline () : boolean {
    return this.payload.st_headline_format?.identifier !== 'default'
  }

  private get listItems () : IHighlightBulletList[] {
    return this.payload.st_list_items?.map((section : Section) => ({
      item: section.data?.st_list_item,
    })).filter((e) => !!e.item) || []
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
