
import { FSXABaseRichTextElement } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'

@Component({ name: 'TextComp' })
export default class Text extends FSXABaseRichTextElement {
  private tags : Record<string, string> = {
    bold: 'strong',
    italic: 'i',
    underline: 'u',
  }

  private get tag () : string {
    const fallback = 'span'
    if (!this.data.format) return fallback
    if (!Object.prototype.hasOwnProperty.call(this.tags, this.data.format)) return fallback
    return this.tags[this.data.format]
  }
}
