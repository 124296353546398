
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'

interface IPayload {
  st_chatId ?: string
}

@Component({ name: 'StChat' })
export default class StChat extends FSXABaseSection<IPayload> {
  mounted () {
    this.loadChat()
    this.addToolbarElement()
  }

  destroyed () {
    window.removeEventListener('chat-open', this.openChat)
    // remove their leftover dom element
    document.querySelector('#designstudio')?.remove()
  }

  private get chatLabel () : string {
    return globalLabelAsString('toolbar_chat')
  }

  private loadChat () : void {
    if (!this.payload.st_chatId) return

    const snapEngageScriptTag = document.createElement('script')
    snapEngageScriptTag.type = 'text/javascript'
    snapEngageScriptTag.async = true
    snapEngageScriptTag.src = `//storage.googleapis.com/code.snapengage.com/js/${this.payload.st_chatId}.js`
    snapEngageScriptTag.onload = this.chatReady

    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode?.insertBefore(snapEngageScriptTag, firstScriptTag)
  }

  private chatReady () : void {
    window.addEventListener('chat-open', this.openChat)
    window.addEventListener('toolbar-unmounted', () => {
      window.SnapEngage?.endChat({ closeChatBox: true })
    })
  }

  private openChat () : void {
    window.SnapEngage?.startLink()
  }

  private addToolbarElement () : void {
    this.$store.commit('ToolbarElements/add', {
      iconName: 'messages',
      text: this.chatLabel,
      customEventName: 'chat-open',
    })
  }
}
