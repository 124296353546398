
import { FSXABaseLayout } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { LinkPropertyHref } from 'vue-meta'
import IReference from '../../../shared/fsxa/interfaces/IReference'

interface IPayload {
  pt_canonical_link ?: string | IReference
  pt_hreflang ?: string
}

interface ILocalizationLink {
  rel : string
  hreflang : string
  href : string
}

@Component({ name: 'BasePageLinks' })
export default class BasePageLinks extends FSXABaseLayout<IPayload> {
  linksArray : LinkPropertyHref[] = []

  pushLink (rel : string, hreflang ?: string, href ?: string) {
    if (!href) return
    if (!hreflang) this.linksArray.push({ rel, href })
    this.linksArray.push({ rel, hreflang, href })
  }

  protected get links () {
    const getLink = (link ?: string | IReference) => {
      if (!link) return ''
      if (typeof link === 'string') return link

      return this.getUrlByPageId(link.referenceId || '') || ''
    }

    this.pushLink('canonical', undefined, getLink(this.data.pt_canonical_link))
    this.localizationLinks.forEach((link) => this.pushLink(link.rel, link.hreflang, link.href))

    return this.linksArray
  }

  /**
   * If you have multiple versions of a page for different languages or regions, tell Google about these different
   * variations.
   * Doing so will help Google Search point users to the most appropriate version of your page by language or region.
   */
  private get localizationLinks () : ILocalizationLink[] {
    const hreflang : string = this.currentDataset?.data?.tt_hreflang || this.data.pt_hreflang || ''

    const extractAttributeValue = (link : string, attributeName : string) : string => {
      const regex = new RegExp(`${attributeName}="(.+?)"`)
      // first on is the whole match e.g. rel="alternate" the second one is the capture group
      return link.match(regex)?.[1] || ''
    }

    return hreflang
      .split('\n') // split by newline
      .map((link) => ({
        rel: extractAttributeValue(link, 'rel'),
        hreflang: extractAttributeValue(link, 'hreflang'),
        href: extractAttributeValue(link, 'href'),
      }))
      .filter((result) => result.rel && result.hreflang && result.href)
  }
}
