
import { Section } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { IPosition } from '../../../shared/general/interfaces/IPosition'

interface IPayload {
  st_headline ?: string
  st_contact ?: Section[]
  st_form ?: Section[]
  st_location ?: Section[]
  st_position_1 ?: IPosition
  st_position_2 ?: IPosition
  st_position_3 ?: IPosition
}

@Component({
  name: 'StContainerLeadGeneration',
  components: {
    BaseGridLayout,
    LeadGeneration: () => import('../../leadGeneration/LeadGeneration.vue'),
  },
})
export default class StContainerLeadGeneration extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string | undefined {
    return this.payload.st_headline
  }

  private get contactData () : Section[] {
    return this.removeFirstHeadline(this.payload.st_contact)
  }

  private get formData () : Section[] {
    return this.removeFirstHeadline(this.payload.st_form)
  }

  private get locationData () : Section[] {
    return this.removeFirstHeadline(this.payload.st_location)
  }

  private get positions () : IPosition[] {
    return [
      this.payload.st_position_1,
      this.payload.st_position_2,
      this.payload.st_position_3,
    ].filter(
      (position) : position is IPosition => !!position,
    )
  }

  private removeFirstHeadline (sections ?: Section[]) : Section[] {
    if (!sections?.length) {
      return []
    }

    const [{ data: fullData, ...otherProperties }, ...otherSections] = sections
    const reducedData = { ...fullData, st_headline: null }
    return [{ data: reducedData, ...otherProperties }, ...otherSections]
  }
}
