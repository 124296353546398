
import { FSXABaseLayout } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'

export interface IPayload {
  pt_service_remote ?: TRemoteDatasetIndex
  pt_show_service_content ?: boolean
}

@Component({ name: 'BaseServicePage' })
export default class BaseServicePage extends FSXABaseLayout<IPayload> {}
