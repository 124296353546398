import {
  Facet, FacetCount, Highlighting, Page, Result, SearchResult, SmartSearch, SmartSearchOptions,
} from 'smartsearch.js'

/**
 * Mapping interface because the actual payload is different from the definition.
 */
interface ISmartSearchSearchResult {
  result : Result
  // TODO: According to the SmartSearch it should be 'HighlightingData' but that doesn't match with the actual payload
  highlights : Highlighting
}

/**
 * Describes a SmartSearch field (can be extended at a later time when more features are needed).
 */
export interface ISmartSearchField {
  /*
   * If a search text does not match a highlight the result ends up either with an 'empty array' or 'undefined'
   * depending on whether 'Partial match' was activated in the UI of the SmartSearch or not.
   */
  isHighlight ?: boolean

  /*
   * You can define the name of the SmartSearch field here.
   * The name of the field for 'highlights' and 'result' (plain text) is the same.
   */
  key : string
}

/**
 * Definition of the generic type for the mapping between a SmartSearch 'facet' and the target return type.
 */
declare type TFacetMappingGenericType<F> = Record<keyof F, (string | number | boolean)[]>

/**
 * Definition of the generic type for the mapping between a SmartSearch 'field' and the target return type.
 */
declare type TFieldMappingGenericType<T> = Partial<Record<keyof T, string>> & {
  link : string // defined as mandatory field - always needs to be included
}

/**
 * Mapping between the target field [key] and the facet name (value).
 *
 * Example: Let's say you want 'filterByDate' but the SmartSearch field name is 'FS_pt_date_from' the mapping could be:
 * ```ts
 *  { filterByDate: 'FS_pt_date_from' }
 * ```
 */
declare type TFacetMapping<F extends TFacetMappingGenericType<F>> = {
  [key in keyof F] : string
}

/**
 * Mapping between the target field [key] and the SmartSearch field (value).
 * The key must match one of the keys of the target's field.
 *
 * Example: Let's say you want a 'headline' but the SmartSearch field name is 'FS_pt_headline' the mapping could be:
 * ```ts
 *  { headline: { key: 'FS_pt_headline' } }
 * ```
 */
declare type TFieldMapping<T extends TFieldMappingGenericType<T>> = {
  [key in keyof T] : ISmartSearchField
}

/**
 * Parameters for searching.
 */
declare type TSearchParams = {
  searchTerm : string
  locale : string
  pageNumber ?: number
  pageSize ?: number
  customParams ?: [string, string][]
}

/**
 * Parameters for the SmartSearch service.
 */
declare type TSmartSearchService = {
  host : string
  preparedSearch : string
  options ?: SmartSearchOptions
}

/**
 * The type returned by the SmartSearch.
 *
 * Type 'T' is the target type for the search results.
 * Type 'F' is the target type for the facets. It is defined with '{}' as default because the generic is (optional).
 */
export declare type TSearchResults<T extends TFieldMappingGenericType<T>, F extends TFacetMappingGenericType<F> = {}> = {
  totalNumberOfResults : number
  searchResults : T[]
  facets : F
}

/**
 * The type returned by the getSmartSearchInstance.
 */
declare type TSmartSearchInstanceClosure = () => Promise<SmartSearch>

/**
 * Creates a new SmartSearch instance by using async import (use current one if already defined).
 * Needed otherwise the pages won't work, instead errors are shown (like 'CustomEvent not defined')
 */
export const getSmartSearchInstance = ({ host, preparedSearch, options } : TSmartSearchService) : TSmartSearchInstanceClosure => {
  let smartSearch : SmartSearch | null = null
  return async () : Promise<SmartSearch> => {
    if (!smartSearch) {
      smartSearch = new (await import('smartsearch.js')).SmartSearch(host, preparedSearch, options)
    }
    return smartSearch
  }
}

/**
 * Remove entries from customParams where value is an 'empty string'.
 *
 * @param customParams parameters to parse
 */
const parseCustomParams = (customParams ?: [string, string][]) : Record<string, string>[] => (
  // this works because one tuple can only have 2 values
  (customParams || [])
    .filter((tuple : [string, string]) => tuple[1] !== '')
    .map((tuple : [string, string]) => ({ [tuple[0]]: tuple[1] }))
)

/**
 * Tries to extract the value from the search result for one smart search field.
 *
 * @param searchResult      contains the data for a single result
 * @param smartSearchField  for which field should the data be retrieved
 */
const extractValue = (searchResult : ISmartSearchSearchResult, smartSearchField : ISmartSearchField) : string => {
  type TResultMapping = string | number | string[] | number[]

  const fallbackForEmptyArray = (arr ?: string[], fallback ?: TResultMapping) => (arr?.length ? arr : fallback)

  // the data for a highlight is stored in a different property, so we have to check
  const { isHighlight, key } : { isHighlight ?: boolean, key : string } = smartSearchField
  const value : (TResultMapping | undefined) = isHighlight
    ? fallbackForEmptyArray(searchResult.highlights?.[key], searchResult.result?.[key])
    : searchResult.result?.[key]

  if (!value) return ''
  if (Array.isArray(value)) {
    const numberToString = (val : string | number) : string => `${val}`.trim()
    const notEmpty = (val : string) : boolean => !!val.length
    return value.map(numberToString).filter(notEmpty).join('|')
  }
  return `${value}`
}

/**
 * Extract the values from the facets and create the target type.
 *
 * @param facetMapping  mapping between SmartSearch facet name and target name
 * @param facets        SmartSearch facets with their values
 */
const extractFacets = <F extends TFacetMappingGenericType<F>> (facetMapping : TFacetMapping<F>, facets : Facet[] = []) : F => {
  const initialValue : {} = {}
  return Object.entries(facetMapping).reduce((previous, [targetKey, facetName]) => ({
    ...previous,
    [targetKey]: facets.find((facet : Facet) : boolean => facet.name === facetName)?.counts
      .flatMap((count : FacetCount) => count.value) || [],
  }), initialValue) as F
}

const mapSearchResult = <T extends TFieldMappingGenericType<T>>
  (searchResult : SearchResult, fieldMapping : TFieldMapping<T>) : T => {
  // Casting to ISmartSearchSearchResult is required the fields of SearchResult are all private and not accessible
  const smartSearchSearchResult : ISmartSearchSearchResult = searchResult as unknown as ISmartSearchSearchResult

  // Note: Object.entries() cannot handle generics which is why reduce() will return an object of Type '{}'.
  // Because we do use the keys defined in the mapping, and they are connected to the type via generics,
  // we can safely assume the result is exactly of type T (+ the link).

  // Error when we don't use 'as T':
  // TS2322: Type '{}' is not assignable to type 'T'.
  //    'T' could be instantiated with an arbitrary type which could be unrelated to '{}'.
  const initialValue : {} = { link: smartSearchSearchResult.result?.link || '#' }

  // We have to exclude the initial value as it is not extracted via SmartSearch field
  return Object.entries(fieldMapping).filter(([key]) : boolean => key !== 'link')
    .reduce((previous, [key, smartSearchField]) => ({
      ...previous,
      [key]: extractValue(smartSearchSearchResult, smartSearchField),
    }), initialValue) as T
}

/**
 *
 * @param page          the SmartSearch page result containing the results and facets
 * @param fieldMapping  mapping between SmartSearch and target fields.
 * @param facetMapping  mapping between SmartSearch facets and target names.
 */
const transformPageIntoSearchResults = <T extends TFieldMappingGenericType<T>, F extends TFacetMappingGenericType<F>>
  (
    page : Page,
    fieldMapping : TFieldMapping<T>,
    facetMapping : TFacetMapping<F>,
  ) : TSearchResults<T, F> => {
  const { searchResults, facets } : { searchResults : SearchResult[], facets : Facet[] } = page

  return {
    totalNumberOfResults: page.numberOfResults,
    searchResults: searchResults
      .map((searchResult : SearchResult) => mapSearchResult(searchResult, fieldMapping)),
    facets: extractFacets(facetMapping, facets),
  }
}

/**
 * Transforms the supplied locale into the SmartSearch compatible format.
 * Supported input formats: xx-XX, XX-XX, XX-xx, xx_XX, XX_XX, XX_xx
 * Result: xx_XX
 *
 * @param locale    the locale to transform
 */
const transformLocale = (locale : string) : string => ((locale.length === 5)
  ? `${locale.substring(0, 2).toLowerCase()}_${locale.substring(3).toUpperCase()}`
  : locale)

/**
 * Internal search function used for other implementations.
 *
 * @param smSearch    SmartSearch instance.
 * @param params      A set of parameters supplied to the underlying search.
 */
const searchInternal = async (smSearch : SmartSearch, {
  searchTerm, locale, pageSize, pageNumber, customParams,
} : TSearchParams) : Promise<Page> => {
  const customURLParams : Record<string, string>[] = []
  const parsedCustomParams : Record<string, string>[] = parseCustomParams(customParams)
  parsedCustomParams.forEach((param : Record<string, string>) => customURLParams.push(param))

  if (pageSize) customURLParams.push({ numRows: `${pageSize}` })
  if (pageNumber) customURLParams.push({ page: `${pageNumber}` })
  customURLParams.push({ 'facet.filter.locale': transformLocale(locale) })

  // the SmartSearch can't handle slashes properly, other special characters are fine
  const safeSearchTerm : string = searchTerm.replaceAll('/', '')
  return smSearch.search(safeSearchTerm, ...customURLParams)
}

/**
 * Helper to execute the search and transform the page result (which contains the search results) to the target type.
 *
 * @param smSearch      SmartSearch instance.
 * @param fieldMapping  mapping between SmartSearch and target fields.
 * @param facetMapping  mapping between SmartSearch facets and target names.
 * @param params        parameters for the search.
 */
const trySearch = async <T extends TFieldMappingGenericType<T>, F extends TFacetMappingGenericType<F>>
(smSearch : SmartSearch, fieldMapping : TFieldMapping<T>, facetMapping : TFacetMapping<F>, params : TSearchParams)
: Promise<TSearchResults<T, F>> => {
  try {
    const page : Page = await searchInternal(smSearch, params)
    return transformPageIntoSearchResults(page, fieldMapping, facetMapping)
  } catch (reason) {
    // eslint-disable-next-line no-console
    console.error(reason)
    return { totalNumberOfResults: 0, searchResults: [], facets: extractFacets(facetMapping) }
  }
}

/**
 * Search functionality by mapping and facet mapping.
 *
 * @param smSearch      (required) SmartSearch instance.
 * @param fieldMapping  (required) mapping between SmartSearch and target fields.
 * @param params        (required) parameters for the search.
 * @param facetMapping  (required) mapping between SmartSearch facets and target names.
 *
 * @see TSearchResults
 * @see TFieldMapping
 * @see TSearchParams
 * @see TFacetMapping
 */
export const searchByFacets = async <T extends TFieldMappingGenericType<T>, F extends TFacetMappingGenericType<F>>
(smSearch : SmartSearch, fieldMapping : TFieldMapping<T>, facetMapping : TFacetMapping<F>, params : TSearchParams)
: Promise<TSearchResults<T, F>> => trySearch(smSearch, fieldMapping, facetMapping, params)

/**
 * Search functionality by mapping.
 *
 * @param smSearch      (required) SmartSearch instance.
 * @param fieldMapping  (required) extract data into target fields from given SmartSearch fields.
 * @param params        (required) parameters for the search.
 *
 * @see TSearchResults
 * @see TFieldMapping
 * @see TSearchParams
 */
export const search = async <T extends TFieldMappingGenericType<T>>(smSearch : SmartSearch, fieldMapping : TFieldMapping<T>, params : TSearchParams)
  : Promise<TSearchResults<T>> => trySearch(smSearch, fieldMapping, {}, params)

export type {
  TFieldMapping as TSmartSearchMapping,
  TFacetMapping as TSmartSearchFacetMapping,
  TSearchParams as TSmartSearchParams,
  TSearchResults as TSmartSearchResults,
}
