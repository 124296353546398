interface IStreetAddress {
  street ?: string
  houseNumber ?: string
}
const formatStreetAddress = (address : IStreetAddress, streetViewOrder : string) : string[] => {
  const format : Record<string, (string | undefined | null)[]> = {
    1: [address.street, address.houseNumber],
    2: [address.houseNumber, address.street],
  }
  const isString = (value ?: string | null) : value is string => (!!value)
  return format[streetViewOrder].filter(isString)
}
export default formatStreetAddress
