import StoreSingleton from './StoreSingleton'

export interface IParams {
  from ?: string
  to ?: string
  dateOptions ?: Intl.DateTimeFormatOptions
}

const dateTimeRegex = /(\d{4}).*T(\d{2}:\d{2}:\d{2})/

export const isNowBeforeTime = (time ?: string) => {
  if (!time) return true

  const now = new Date()
  const nowMillis = now.getTime()

  const [,, targetTime] = dateTimeRegex.exec(time)!
  const [hoursTarget, minutesTarget] = targetTime.split(':')

  const targetDate = new Date()
  targetDate.setFullYear(now.getFullYear(), now.getMonth(), now.getDate())
  targetDate.setHours(+hoursTarget)
  targetDate.setMinutes(+minutesTarget)

  return nowMillis < targetDate.getTime()
}

export const isInNowTimeRange = (from ?: string, to ?: string) => {
  if (!from || !to) return false

  const now = new Date()
  const nowMillis = now.getTime()

  const [,, timeFrom] = dateTimeRegex.exec(from)!
  const [hoursFrom, minutesFrom] = timeFrom.split(':')
  const fromDate = new Date()

  const [,, timeTo] = dateTimeRegex.exec(to)!
  const [hoursTo, minutesTo] = timeTo.split(':')
  const toDate = new Date()

  fromDate.setFullYear(now.getFullYear(), now.getMonth(), now.getDate())
  fromDate.setHours(+hoursFrom)
  fromDate.setMinutes(+minutesFrom)
  toDate.setFullYear(now.getFullYear(), now.getMonth(), now.getDate())
  toDate.setHours(+hoursTo)
  toDate.setMinutes(+minutesTo)

  return nowMillis >= fromDate.getTime() && nowMillis <= toDate.getTime()
}

const getDateFromTimeString = (timeString : string) : string => {
  const date = new Date()
  const [hours, minutes, seconds] = timeString.split(':')

  date.setHours(+hours)
  date.setMinutes(+minutes)
  date.setSeconds(+seconds)

  return date.toISOString()
}

// the iso date coming from the backend is in UTC even though it shouldn't be, therefor we have to parse out the time and ignore the rest
export const parseDateTimeString = (isoString : string) : string => {
  // example: matches 2011-10-05T14:48:00.000Z to 2011-10-05 (date) and 14:48:00 (time)
  const match : RegExpExecArray | null = dateTimeRegex.exec(isoString)

  if (!match) return isoString

  const [, year, time] : RegExpExecArray = match

  // we get a iso date string with the year 1970 if it's about the time otherwise we obviously get a normal date and return that
  if (year !== '1970') return isoString

  return getDateFromTimeString(time)
}

export const formatJoinFromToDate = (params : IParams) : string => {
  const {
    from, to, dateOptions,
  } = params

  if (!from) return ''
  const options = dateOptions || {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  }
  const { locale } = StoreSingleton.instance.state.Locale
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options)

  return to
    ? dateTimeFormat.formatRange(new Date(parseDateTimeString(from)), new Date(parseDateTimeString(to)))
    : dateTimeFormat.format(new Date(parseDateTimeString(from)))
}

// for dates in the form of XXXX-XX-XXT00:00:00.000Z
export const isNowDateInDateRange = (fromDate ?: string, toDate ?: string) : boolean => {
  // when no dates are supplied = always match
  if (!fromDate && !toDate) return true
  if (fromDate && toDate) {
    const now : number = Date.now()
    const startOfDay : number = new Date(fromDate).setHours(0, 0, 0)
    const endOfDay : number = new Date(toDate).setHours(23, 59, 59)
    return (now >= startOfDay && now <= endOfDay)
  }
  if (fromDate) {
    const startOfDay : number = new Date(fromDate).setHours(0, 0, 0)
    return Date.now() >= startOfDay
  }
  if (toDate) {
    const endOfDay : number = new Date(toDate).setHours(23, 59, 59)
    return Date.now() <= endOfDay
  }
  return false
}
