
import { FSXABaseSection, FSXARenderContentElement } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { Section } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import IOption from '../../../shared/general/interfaces/IOption'
import { THeadlineFormat } from '../../base/BaseHeadline.vue'
import { notUndefined } from '../../../shared/general/services/TypeAssertions'

interface IPayloadElement {
  id ?: string
  data ?: {
    st_content ?: Section[]
    st_headline ?: string
  }
}

interface IPayload {
  st_design_choice ?: {
    identifier ?: 'accordion' | 'tabs'
  }
  st_open_first_accordion_element ?: boolean
  st_elements ?: IPayloadElement[]
  st_headline ?: string
  st_headline_format ?: IOption
}

interface IContainerTabsAccordion {
  id ?: string
  content ?: Section[]
  headline ?: string
}

@Component({
  name: 'StContainerTabsAccordion',
  components: {
    BaseGridLayout,
    TabAccordion: () => import('../../tabAccordion/TabAccordion.vue'),
    TabAccordionElement: () => import('../../tabAccordion/TabAccordionElement.vue'),
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
    FSXARenderContentElement,
  },
})
export default class StContainerTabsAccordion extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string | undefined {
    return this.payload.st_headline
  }

  private get headlineFormat () : THeadlineFormat | undefined {
    if (!['h1', 'h2', 'h3'].includes(this.payload.st_headline_format?.identifier || '')) return 'h3'
    return (this.payload.st_headline_format?.identifier as THeadlineFormat | undefined) || 'h3'
  }

  private get headlineSemantic () : boolean {
    return ['h1', 'h2', 'h3'].includes(this.payload.st_headline_format?.identifier || '')
  }

  private get designChoice () : 'accordion' | 'tabs' {
    return this.payload.st_design_choice?.identifier || 'tabs'
  }

  private get openFirstAccordionElement () : boolean {
    return notUndefined(this.payload.st_open_first_accordion_element) ? this.payload.st_open_first_accordion_element : true
  }

  private get tabAccordionElements () : IContainerTabsAccordion[] {
    const mapElement = (element : IPayloadElement) : IContainerTabsAccordion => ({
      id: element.id || '',
      content: element.data?.st_content || [],
      headline: element.data?.st_headline || '',
    })
    return this.payload.st_elements?.map(mapElement) || []
  }
}
