
import { Component } from 'nuxt-property-decorator'
import BaseContentProjection from '../../base/BaseContentProjection.vue'
import ContentProjection from '../../../ContentProjection.vue'

@Component({
  name: 'TtArticle',
  components: { ContentProjection },
})
export default class TtArticle extends BaseContentProjection {
  beforeCreate () {
    this.type = 'article'
  }
}
