import { CaaSApiMediaPictureResolutions, Image } from 'fsxa-api'
import IImageResolution from '../interfaces/IImageResolution'
import IImage from '../interfaces/IImage'
import { TImageAltTextAndTitle } from '../types/TImageAltTextAndTitle'

const mapToResolutions = (resolutions : null | CaaSApiMediaPictureResolutions = {}) : IImageResolution[] => {
  if (!resolutions) return []
  const entries = Object.entries(resolutions)
  return entries.map<IImageResolution>(([name, values]) => ({ name, ...values }))
}

const getImageAltTextAndTitle = (imageAltText ?: string, imageTitle ?: string, decorative ?: boolean) : TImageAltTextAndTitle => {
  if (decorative) return { altText: undefined, title: undefined }
  return { altText: imageAltText, title: imageTitle }
}

const mapImage = (image ?: Image, altText ?: string, title ?: string, decorative ?: boolean) : IImage => ({
  resolutions: mapToResolutions(image?.resolutions),
  ...getImageAltTextAndTitle(altText, title, decorative),
})

export default mapToResolutions
export { mapImage }
