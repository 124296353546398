
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import IFormPayload, { IFormData } from '../../../shared/fsxa/interfaces/IFormPayload'

@Component({
  name: 'StPrivateOrderForm',
  components: {
    BaseForm: () => import('../../base/BaseForm.vue'),
  },
})
export default class StPrivateOrderForm extends FSXABaseSection<IFormPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get form () : IFormData {
    return this.payload.form?.value || { uid: '', fd: '', lang: '' }
  }
}
