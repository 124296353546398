
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Link } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { ILink } from '../../../shared/general/interfaces/ILink'
import getLinkObject from '../../../shared/fsxa/services/LinkService'

interface IPayload {
  st_link_text ?: string
  st_title_text ?: string
  st_show_default_link ?: boolean
  st_link ?: Link
}

@Component({
  name: 'StLocationOnlineAppointmentCta',
  components: {
    BaseGridLayout,
    BaseCta: () => import('../../base/BaseCta.vue'),
  },
})
export default class StLocationOnlineAppointmentCta extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get cta () : ILink {
    const { link } : { link : string } = this.$store.state.LocationOnlineAppointment
    if (link) {
      return {
        url: link,
        label: this.payload.st_link_text,
        title: this.payload.st_title_text,
        iconName: 'calendar-days',
      }
    }
    if (this.payload.st_show_default_link && this.payload.st_link) {
      return {
        ...getLinkObject(this.payload.st_link, this.getUrlByPageId),
        iconName: 'calendar-days',
      }
    }
    return {}
  }
}
