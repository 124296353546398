
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import BaseSearchbar from '../base/BaseSearchbar.vue'
import BaseIcon from '../base/BaseIcon.vue'
import BaseIconButton from '../base/BaseIconButton.vue'

@Component({
  name: 'HeaderSearchComponent',
  components: {
    BaseSearchbar,
    BaseIcon,
    BaseIconButton,
  },
})
export default class HeaderSearchComponent extends Vue {
  @Prop({ required: true }) isMobile! : boolean

  $refs! : {
    searchBar : BaseSearchbar
  }

  @Emit('close-searchbar')
  private closeSearchbar () : boolean {
    return true
  }

  @Emit('search')
  private search (value : string) : string {
    return value
  }

  public focusInput () : void {
    this.$refs.searchBar.focusInput()
  }
}
