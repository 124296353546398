import { FSXAProxyApi, ProjectProperties } from 'fsxa-api'
import fsxaProxyApiLocal from './FsxaProxyApiLocal'
import fsxaProxyApiRemote from './FsxaProxyApiRemote'
import { Logger } from '../../general/logger/Logger'
import { func, maintenance } from '../../general/logger/LogKey'
import { TMaintenanceData } from '../../general/types/TMaintenanceData'

const fetchMaintenanceData = async (api : FSXAProxyApi, locale : string) : Promise<TMaintenanceData> => {
  try {
    const properties : ProjectProperties | null = await api.fetchProjectProperties({ locale })
    return {
      activate: properties?.data?.ps_maintenance_bar_active || false,
      text: properties?.data?.ps_maintenance_bar_text || '',
    }
  } catch (error) {
    Logger.error(maintenance, func('fetchMaintenanceData'), 'Failed to fetch fetchProjectProperties.', error)
    return { activate: false, text: '' }
  }
}

export default async (locale : string) => {
  const remoteMaintenanceData : TMaintenanceData = await fetchMaintenanceData(fsxaProxyApiRemote, locale)
  if (remoteMaintenanceData.activate) return remoteMaintenanceData
  return fetchMaintenanceData(fsxaProxyApiLocal, locale)
}
