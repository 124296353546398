export interface NotificationData {
    changes ?: { key : string, value ?: any }
    type : 'set' | 'delete' | 'clear'
}

export class ObservableMap extends Map {
  private readonly observerCallbacks : Function[]

  constructor () {
    super()
    this.observerCallbacks = []
  }

  public observe (callback : Function) : void {
    this.observerCallbacks.push(callback)
  }

  public set (key : string, value : any, forceOverride = false) : this {
    if (this.get(key) && !forceOverride) return this
    super.set(key, value)
    this.notify({ changes: { key, value }, type: 'set' })
    return this
  }

  public delete (key : string) : boolean {
    super.delete(key)
    this.notify({ changes: { key }, type: 'delete' })
    return true
  }

  public clear () : void {
    super.clear()
    this.notify({ type: 'clear' })
  }

  private notify (data : NotificationData) : void {
    this.observerCallbacks.forEach((callback) => callback(data))
  }
}
