import { File } from 'fsxa-api'
import { IHtmlPlayerElements } from '../interfaces/IHtmlPlayerElements'
import { TVideoFormat } from '../types/TVideoFormat'

interface IVideoUrl {
  lightbox : (videoId : string, privacyHash ?: string) => string
  redirect : (videoId : string) => string
}

const urlLinksPicker : Record<TVideoFormat, IVideoUrl> = {
  youtube: {
    lightbox: (videoId : string) => `https://www.youtube.com/embed/${videoId}?autoplay=1&muted=1`,
    redirect: (videoId : string) => `https://www.youtube.com/watch?v=${videoId}`,
  },
  vimeo: {
    lightbox: (videoId : string, privacyHash ?: string) => {
      const baseUrl : string = `https://player.vimeo.com/video/${videoId}`
      if (!privacyHash) return `${baseUrl}?autoplay=1&muted=1`
      return `${baseUrl}?h=${privacyHash}&autoplay=1&muted=1`
    },
    redirect: (videoId : string) => `https://vimeo.com/${videoId}`,
  },
  tencent: {
    lightbox: (videoId : string) => `https://v.qq.com/txp/iframe/player.html?vid=${videoId}`,
    redirect: (videoId : string) => `https://v.qq.com/x/page/${videoId}.html`,
  },
  html5: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    lightbox: (videoId : string) => '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    redirect: (videoId : string) => '',
  },
}

export const getVideoUrl = (videoId : string, format : TVideoFormat, redirect : boolean, privacyHash ?: string) : string => {
  if (!redirect) return urlLinksPicker[format].lightbox(videoId, privacyHash)
  return urlLinksPicker[format].redirect(videoId)
}

interface IBuildPlayerElementsProps {
  ogv ?: File
  webm ?: File
  mp4 ?: File
}

export const buildPlayerElements = (props : IBuildPlayerElementsProps) : IHtmlPlayerElements => {
  const addPlayerElement = (player : IHtmlPlayerElements, videoFormat : 'mp4' | 'webm' | 'ogv') => {
    if (!props[videoFormat]) return

    player[videoFormat] = {
      url: props[videoFormat]?.url || '#',
      mimeType: props[videoFormat]?.fileMetaData?.mimeType || `video/${videoFormat}`,
    }
  }

  const playerElements = {} as IHtmlPlayerElements
  addPlayerElement(playerElements, 'mp4')
  addPlayerElement(playerElements, 'webm')
  addPlayerElement(playerElements, 'ogv')
  return playerElements
}

export const getVideoFormat = (format ?: string) : TVideoFormat => {
  if (!format) return 'html5'
  switch (format) {
    case 'html5Video':
      return 'html5'
    case 'html5':
    case 'youtube':
    case 'vimeo':
    case 'tencent':
      return format
    default:
      return 'html5'
  }
}
