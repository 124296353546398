
import { Component } from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import BaseTeaserEventsSection from '../base/BaseTeaserEventsSection.vue'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { globalLabel } from '../../../shared/general/services/StoreService'

@Component({
  name: 'StTeaserEventsContent',
  components: {
    BaseGridLayout,
    GlobalLabelWrapper: () => import('../../GlobalLabelWrapper.vue'),
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
    BaseLink: () => import('../../base/BaseLink.vue'),
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
  },
})
export default class StTeaserEventsContent extends BaseTeaserEventsSection {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get noEventsAvailableLabel () : string| RichTextElement[] {
    return globalLabel('no_events_available_label')
  }
}
