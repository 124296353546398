
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import IPill from '../../shared/general/interfaces/IPill'
import BaseIcon from './BaseIcon.vue'

@Component({
  name: 'BasePill',
  components: { BaseIcon },
})
export default class BasePill extends Vue {
  private cChecked : boolean = false

  private cDisabled : boolean = false

  @Prop() label! : string

  @Prop() value! : string

  @Prop({ default: false }) checked! : boolean

  @Prop({ default: false }) disabled! : boolean

  @Prop({ default: false }) large! : boolean

  created () {
    this.cChecked = this.checked
    this.cDisabled = this.disabled
  }

  @Watch('checked')
  onValueChange (val : boolean) {
    this.cChecked = val
  }

  @Watch('disabled')
  onDisabledChange (val : boolean) {
    this.cDisabled = val
  }

  @Emit('click')
  click (event : MouseEvent) : IPill {
    event.preventDefault()
    event.stopPropagation()

    if (!this.cDisabled) {
      this.cChecked = !this.cChecked
    }

    return {
      label: this.label,
      value: this.value,
      checked: this.cChecked,
      disabled: this.cDisabled,
    }
  }

  get focusStyle () : string {
    if (this.cDisabled) return 'focus:outline-none focus-visible:outline-none'
    return 'focus:outline-none focus-visible:ring focus-visible:ring-darkgreen-200'
  }

  get boxStyle () : string {
    if (this.cDisabled) return 'bg-gray-50 border-gray-300 text-gray-400 cursor-default'
    if (this.cChecked) return 'bg-darkgreen border-darkgreen text-white'
    return 'transition-all duration-300 bg-transparent hover:bg-darkgreen-50 border-darkgreen'
    + 'border-darkgreen-600 text-darkgreen hover:text-darkgreen-600 active:bg-darkgreen-50'
  }

  get textStyle () : string {
    return this.large ? 'text-base' : 'text-sm'
  }
}
