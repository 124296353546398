
import { RichTextElement } from 'fsxa-api'
import {
  Component, Prop, Vue,
} from 'nuxt-property-decorator'
import { TMessageBarColor } from '../shared/general/types/TMessageBarColor'
import { TIcon } from '../shared/general/types/TIcon'

@Component({
  name: 'MessageBar',
  components: {
    RichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
    BaseIcon: () => import('./base/BaseIcon.vue'),
    GlobalLabelWrapper: () => import('./GlobalLabelWrapper.vue'),
  },
})
export default class MessageBar extends Vue {
  @Prop({ required: true }) color! : TMessageBarColor

  @Prop() iconName ?: string

  @Prop({ default: 'light' }) iconType! : TIcon

  @Prop({ default: 'small' }) size! : 'small' | 'large'

  @Prop({ default: '' }) text! : string | RichTextElement[]

  private isString = (value ?: string | RichTextElement[]) : value is string => (typeof value === 'string' && !!value.length)

  private colors : Record<string, string> = {
    green: 'bg-brightgreen-300 text-darkgreen-600',
    yellow: 'bg-yellow text-yellow-800',
    red: 'bg-red-200 text-red-800',
  }
}
