
import { FSXABaseRichTextElement } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'

@Component({ name: 'List' })
export default class List extends FSXABaseRichTextElement {
  private get tag () : string {
    return this.data?.['data-fs-style'] === '2' ? 'ol' : 'ul'
  }

  private get classes () : string {
    return this.tag === 'ul' ? 'custom-square' : 'custom-decimal'
  }
}
