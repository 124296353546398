
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { Image } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { mapImage } from '../../../shared/general/services/ImageService'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import normalizeDataEntries from '../../../shared/fsxa/services/DataEntryService'
import { TDataEntry } from '../../../shared/fsxa/types/TDataEntry'
import ILinkData from '../../../shared/fsxa/interfaces/ILinkData'
import { TReferenceElement } from '../../../shared/general/types/TReferenceElement'

type TReferenceListElement = {
  st_image ?: Image
  st_image_decorative ?: boolean
  st_alt_text ?: string
  st_link ?: ILinkData
}

type TPayload = {
  st_headline ?: string
  st_references_list ?: TDataEntry<TReferenceListElement>[]
}

@Component({
  name: 'StReferences',
  components: {
    BaseGridLayout,
    References: () => import('../../References.vue'),
  },
})
export default class StReferences extends FSXABaseSection<TPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string {
    return this.payload.st_headline || ''
  }

  private get references () : TReferenceElement[] {
    return normalizeDataEntries(this.payload.st_references_list).map((element : TReferenceListElement) => ({
      link: getLinkObject(element.st_link, this.getUrlByPageId),
      image: mapImage(element.st_image, element.st_alt_text, element.st_alt_text, element.st_image_decorative),
    }))
  }
}
