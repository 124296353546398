
import { RichTextElement } from 'fsxa-api'
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'GlobalLabelWrapper',
  components: {
    FSXARichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class GlobalLabelWrapper extends Vue {
  @Prop({ required: true }) label! : string | RichTextElement[]

  private get isString () : boolean {
    return typeof this.label === 'string'
  }
}
