
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import BaseFlyout from '../BaseFlyout.vue'
import IOption from '../../../shared/general/interfaces/IOption'
import BaseIcon from '../BaseIcon.vue'

@Component({
  name: 'BaseDropdown',
  components: {
    BaseFlyout,
    BaseIcon,
  },
})
export default class BaseDropdown extends Vue {
  @Prop() option ?: IOption

  @Prop({ default: () => [] }) options! : IOption[]

  @Prop({ default: false }) disabled! : boolean

  @Prop({ default: false }) error! : boolean

  @Prop({ default: false }) preselectFirst! : boolean

  @Prop() message ?: string

  @Prop({ required: true }) label! : string

  private flyoutVisible : boolean = false

  private currentOption : IOption = { label: '', value: '' }

  created () {
    this.closeOnOutsideClick()
  }

  mounted () {
    if (this.options.length > 0 && !this.selectCustomOption() && this.preselectFirst) {
      this.selectFirstOption()
    }
  }

  private get buttonStyle () {
    if (this.disabled) return 'focus:outline-none focus-visible:outline-none bg-gray-400 border-gray-300 cursor-default'
    if (this.error) {
      const focusWhenOpen = this.flyoutVisible ? 'outline outline-4 outline-red-300' : ''
      return `${focusWhenOpen} bg-white border-red `
      + 'focus:outline focus:outline-4 focus:outline-red-300'
    }
    const focusWhenOpen = this.flyoutVisible ? 'outline outline-4 outline-darkgreen-200 border-darkgreen' : 'border-gray-300'
    return `${focusWhenOpen} bg-white `
      + 'focus:outline focus:outline-4 focus:outline-darkgreen-200 focus:border-darkgreen'
  }

  private get valueSetStyles () {
    return this.currentOption.value ? 'text-xs top-2' : 'top-5'
  }

  @Watch('option')
  private onOptionChange () {
    this.selectCustomOption()
  }

  private selectCustomOption () : boolean {
    const optionValue = this.option?.value
    if (!optionValue) return false
    const newOption = this.options.find((option) => option.value === optionValue)
    if (newOption) this.selectOption(newOption)
    return !!newOption
  }

  private selectFirstOption () : void {
    [this.currentOption] = this.options
    this.selectOption(this.currentOption)
  }

  @Emit('select')
  private selectOption (option : IOption) : IOption {
    this.flyoutVisible = false
    this.currentOption = option
    this.$el.querySelector('button')?.focus()

    return option
  }

  @Watch('options')
  private updateCurrentOption () {
    const currentValue = this.currentOption?.value
    if (!currentValue) return

    const newOption = this.options.find((option) => option.value === currentValue)
    if (newOption) {
      this.currentOption = newOption
    } else if (this.preselectFirst && this.options.length > 0) {
      [this.currentOption] = this.options
    } else {
      this.currentOption = { label: '', value: '' }
    }
  }

  private toggleFlyout () {
    this.flyoutVisible = !this.flyoutVisible
  }

  /*
   * Sadly, in this case we need the event listener on the document to check if there was an outside click to close
   * the flyout.
   */
  private closeOnOutsideClick () {
    document.addEventListener('click', (e : MouseEvent) => {
      if (this.$el.contains(e.target as Node)) return
      this.flyoutVisible = false
    })
  }
}
