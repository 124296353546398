
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Image, Link, Option } from 'fsxa-api'
import { mapImage } from '../../../shared/general/services/ImageService'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { TTeaserVariant } from '../../../shared/general/types/TTeaserVariant'
import EButtonVariant from '../../../shared/general/enums/EButtonVariant'
import IImage from '../../../shared/general/interfaces/IImage'

interface IPayload {
  st_alt_text ?: string
  st_headline ?: string
  st_image ?: Image
  st_image_decorative ?: boolean
  st_link ?: Link
  st_teaser_type ?: {
    identifier ?: 'small' | 'large'
  }
  st_text ?: string
  st_background_color ?: Option
  st_toggle_image_position ?: boolean
}

@Component({
  name: 'StTeaserImageText',
  components: {
    BaseGridLayout,
    BaseTeaser: () => import('../../base/BaseTeaser.vue'),
    BaseTeaserLarge: () => import('../../base/BaseTeaserLarge.vue'),
  },
})
export default class StTeaserImageText extends FSXABaseSection<IPayload> {
  private get isTeaserTypeLarge () : boolean {
    return this.payload.st_teaser_type?.identifier === 'large'
  }

  private get componentTag () : 'BaseTeaserLarge' | 'BaseTeaser' {
    return this.isTeaserTypeLarge
      ? 'BaseTeaserLarge'
      : 'BaseTeaser'
  }

  private get image () : IImage {
    return mapImage(this.payload.st_image, this.payload.st_alt_text, this.payload.st_alt_text, this.payload.st_image_decorative)
  }

  private get size () : 'wide' | 'small' {
    return this.isTeaserTypeLarge && this.image.resolutions.length
      ? 'wide'
      : 'small'
  }

  private get background () : string {
    const bgColors = {
      white: 'bg-white',
      grey: 'bg-gray-50',
      green: 'bg-darkgreen',
    }
    return bgColors[this.payload.st_background_color?.key || 'white']
  }

  private get variant () : TTeaserVariant {
    let result = 'horizontal-image'
    result += this.payload.st_toggle_image_position ? '-start' : '-end'
    if (this.isTeaserTypeLarge) result += '-large'

    return result as TTeaserVariant
  }

  private get sizeClasses () : string {
    if (this.componentTag === 'BaseTeaserLarge' && this.size === 'small') return ''
    return this.size === 'wide' ? 'pb-6 md:pb-0' : ''
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get teaser () : ITeaserData {
    return {
      image: this.image,
      headline: this.payload.st_headline || '',
      link: {
        ...getLinkObject(this.payload.st_link, this.getUrlByPageId),
        type: this.isTeaserTypeLarge ? 'button' : 'link',
        buttonVariant: this.background === 'bg-darkgreen' ? EButtonVariant.Accent : EButtonVariant.Default,
      },
      teaserVariant: this.variant,
      mediaType: 'image',
      fullSizeImage: true,
      text: this.payload.st_text,
      large: this.isTeaserTypeLarge,
      aspectRatio: this.isTeaserTypeLarge ? '16:9' : '',
      showDownloadIcon: true,
      bgColor: this.background,
    }
  }
}
