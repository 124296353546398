
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseIcon from './base/BaseIcon.vue'
import BaseGridLayout from './layouts/BaseGridLayout.vue'

@Component({
  name: 'LifespanInfo',
  components: {
    BaseIcon,
    BaseGridLayout,
  },
})
export default class Lifespan extends Vue {
  @Prop() start ?: string

  @Prop() end ?: string

  @Prop({ default: 'en-US' }) locale! : string

  private renderDate (iso : string) : string {
    if (!iso) return '(date missing)'
    return new Date(iso).toLocaleString(this.locale)
  }
}
