
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'TeaserRcWrapper',
  components: { BaseGridLayout: () => import('../../layouts/BaseGridLayout.vue') },
})
export default class TeaserRcWrapper extends Vue {
  private get hasSlotContent () : boolean {
    return !!this.$slots.default && !!this.$slots.default?.length
  }
}
