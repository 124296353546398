
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import IOption from '../../shared/general/interfaces/IOption'
import { ILink } from '../../shared/general/interfaces/ILink'
import isExternalURL from '../../shared/fsxa/services/ExternalLinkService'
import ELinkTarget from '../../shared/general/enums/ELinkTarget'

@Component({
  name: 'HeaderUpperSection',
  components: {
    BaseGridLayout,
    BaseLink: () => import('../base/BaseLink.vue'),
    HeaderLanguageSelection: () => import('./HeaderLanguageSelection.vue'),
  },
})
export default class HeaderUpperSection extends Vue {
  @Prop({ required: true }) languages! : IOption[]

  private get contactUrl () : string {
    return this.$store.state.PageHeader.contactPage || ''
  }

  private get link () : ILink | null {
    if (!this.contactUrl) return null

    const internal = !isExternalURL(this.contactUrl)
    return {
      target: internal ? ELinkTarget.Self : ELinkTarget.Blank,
      globalLabelKey: 'contact_label',
      url: this.contactUrl,
      iconName: 'envelope',
      color: 'black',
    }
  }

  @Emit('select-language')
  private selectLanguage (language : IOption) : IOption {
    return language
  }
}
