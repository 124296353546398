interface IPostCodeAddress {
  city ?: string
  zipCode ?: string
  state ?: string
}
const formatPostCodeAddress = (address : IPostCodeAddress, postCodeViewOrder : string) : string[] => {
  const format : Record<string, (string | undefined | null)[]> = {
    1: [address.city, address.zipCode],
    2: [address.zipCode, address.city],
    3: [address.city, address.state, address.zipCode],
    4: [address.zipCode, address.city, address.state],
  }
  const isString = (value ?: string | null) : value is string => (!!value)
  return format[postCodeViewOrder].filter(isString)
}
export default formatPostCodeAddress
