
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { Section } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface IPayload {
  st_headline ?: string
  st_teaser ?: Section[]
}

@Component({
  name: 'StIconCarousel',
  components: {
    BaseGridLayout,
    IconCarousel: () => import('../../iconCarousel/IconCarousel.vue'),
  },
})
export default class StIconCarousel extends FSXABaseSection<IPayload> {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  private get headline () : string | undefined {
    return this.payload.st_headline
  }

  private get sections () : Section[] {
    return this.payload.st_teaser || []
  }
}
