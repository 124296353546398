
import { Component } from 'nuxt-property-decorator'
import { FSXABaseSection, FSXARichText as RichText } from 'fsxa-pattern-library'
import { ComparisonQueryOperatorEnum, RichTextElement } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { IDrsProperties } from '../../drs/Drs.vue'
import BaseSearchInput from '../../base/form/BaseSearchInput.vue'
import { globalLabel } from '../../../shared/general/services/StoreService'

interface IPayload {
  st_label_result ?: string
  st_placeholder_search_input ?: string
  st_label_mechanics ?: string
  st_label_bodywork ?: string
  st_label_paintwork ?: string
  st_label_car ?: string
  st_label_truck ?: string
  st_text ?: RichTextElement[]
}

interface IFsxaDrsData {
  data : {
    tt_id : string
    tt_zip_code : number
    tt_location ?: string
    tt_car_mechanics ?: number
    tt_car_bodywork ?: number
    tt_car_paintwork ?: number
    tt_truck_mechanics ?: number
    tt_truck_bodywork ?: number
    tt_truck_paintwork ?: number
  }
}

@Component({
  name: 'StDrs',
  components: {
    RichText,
    BaseSearchInput,
    BaseGridLayout,
    GlobalLabelWrapper: () => import('../../GlobalLabelWrapper.vue'),
    Drs: () => import('../../drs/Drs.vue'),
  },
})
export default class StDrs extends FSXABaseSection<IPayload> {
  private drsData : IFsxaDrsData[] = []

  private showLoadingAnimation : boolean = false

  private showNoResults : boolean = false

  private get noSearchResultsFound () : string | RichTextElement[] {
    return globalLabel('no_search_results_found_label')
  }

  private get placeholderSearchInput () : string | undefined {
    return this.payload.st_placeholder_search_input
  }

  private get additionalText () : RichTextElement[] {
    return this.payload.st_text || []
  }

  private get drsProperties () : IDrsProperties {
    const {
      st_label_result: results = '',
      st_label_mechanics: mechanics = '',
      st_label_bodywork: bodywork = '',
      st_label_paintwork: paintwork = '',
      st_label_car: car = '',
      st_label_truck: truck = '',
    } = this.payload || {}
    return {
      labels: {
        results, mechanics, bodywork, paintwork, car, truck,
      },
      drsData: this.drsData.map((drs) => ({
        id: drs.data.tt_id,
        zipCode: drs.data.tt_zip_code,
        location: drs.data?.tt_location,
        car: {
          mechanics: drs.data?.tt_car_mechanics,
          bodywork: drs.data?.tt_car_bodywork,
          paintwork: drs.data?.tt_car_paintwork,
        },
        truck: {
          mechanics: drs.data?.tt_truck_mechanics,
          bodywork: drs.data?.tt_truck_bodywork,
          paintwork: drs.data?.tt_truck_paintwork,
        },
      })),
    }
  }

  private async fetchDrsData (zipCode : string) : Promise<void> {
    if (this.drsData.length) this.drsData = []

    const matches : RegExpMatchArray | null = zipCode.match(/^[0-9]{5}$/)
    if (!matches?.length) {
      this.showNoResults = true
      return
    }

    this.showLoadingAnimation = true
    this.showNoResults = false

    try {
      let page = 1
      let newDrs : IFsxaDrsData[] = []
      do {
        // eslint-disable-next-line no-await-in-loop
        newDrs = await this.fetchDrs(page, +matches[0])
        this.drsData.push(...newDrs)
        page += 1
      } while (newDrs.length === 100)
    } finally {
      this.showLoadingAnimation = false
    }

    if (!this.drsData.length) {
      this.showNoResults = true
    }
  }

  private async fetchDrs (page : number, zipCode : number) : Promise<IFsxaDrsData[]> {
    const additionalFilter = {
      $and: [{ 'formData.tt_zip_code.value': { $eq: zipCode } }, { 'formData.tt_id.value': { $ne: null } }],
    }

    const { items } = await this.fsxaApi.fetchByFilter({
      filters: [
        {
          field: 'entityType',
          operator: ComparisonQueryOperatorEnum.EQUALS,
          value: 'drs',
        },
        {
          field: 'schema',
          operator: ComparisonQueryOperatorEnum.EQUALS,
          value: 'local_data',
        },
      ],
      additionalParams: {
        filter: additionalFilter,
      },
      locale: this.locale,
      page,
      pagesize: 100,
    })

    return (items as IFsxaDrsData[])
      .filter((item) => !!item.data?.tt_id && !!item.data?.tt_zip_code)
      .map((item) => item)
  }
}
