var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseLink',_vm._b({staticClass:"relative group bg-white hover:bg-darkgreen active:bg-darkgreen-600 rounded-lg max-w-full !items-start shadow hover:shadow-xl active:shadow-xl overflow-hidden",attrs:{"only-slot-content":true}},'BaseLink',_vm.link,false),[_c('div',{class:_vm.wrapperClasses[_vm.teaserVariant]},[(_vm.downloadIconVisible)?_c('div',{staticClass:"relative col-start-1 h-16 flex items-center justify-center sm:h-full sm:col-span-1 bg-darkgreen group-active:bg-darkgreen-600 duration-300 transition"},[_c('BaseIcon',{attrs:{"name":"file-arrow-down","type":"light","classes":"text-white h-10"}})],1):(_vm.showDateAndLocation)?_c('div',{staticClass:"p-5 pb-0 md:p-5 duration-300 transition group-hover:text-white flex flex-col gap-1",class:_vm.imageWrapperClasses[_vm.teaserVariant]},[(_vm.date)?_c('span',{staticClass:"flex gap-2 items-center"},[_c('BaseIcon',{attrs:{"name":"calendar-days","type":"light","classes":"py-[3px] h-5"}}),_vm._v(" "),_c('span',{staticClass:"col-auto text-gray-500 group-hover:text-white duration-300 transition text-sm sm:text-base"},[_vm._v("\n          "+_vm._s(_vm.date)+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.location)?_c('span',{staticClass:"flex gap-2 items-center"},[_c('BaseIcon',{attrs:{"name":"location-dot","type":"light","classes":"py-[3px] h-5"}}),_vm._v(" "),_c('span',{staticClass:"col-auto text-gray-500 group-hover:text-white duration-300 transition text-sm sm:text-base"},[_vm._v("\n          "+_vm._s(_vm.location)+"\n        ")])],1):_vm._e()]):(_vm.showMedia)?_c('div',{staticClass:"relative w-full",class:[
        _vm.imageHeightClass,
        _vm.aspectRatioClasses[_vm.aspectRatio],
        _vm.imageWrapperClasses[_vm.teaserVariant],
      ]},[_c('BaseImageVideo',{staticClass:"before:block before:absolute before:inset-0 before:z-10 before:pointer-events-none before:duration-300 transition group-hover:before:bg-darkgreen/50",class:_vm.imageHeightClass,attrs:{"media-type":_vm.mediaType,"image":_vm.image,"video":_vm.video,"force-ratio":_vm.forceRatio,"aspect-ratio":_vm.teaserVariant.includes('horizontal-image-') ? '4:3' : _vm.aspectRatio,"breakpoints":{
          600: _vm.teaserVariant.includes('horizontal-image-') ? '4:3' : _vm.aspectRatio,
        }},on:{"click":(content) => _vm.showLightbox(content)}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col duration-300 transition z-20 group-hover:bg-darkgreen group-hover:text-white group-active:bg-darkgreen-600 group-active:text-white",class:[_vm.boxClasses, _vm.bgColor]},[((_vm.date || _vm.location || _vm.tags) && !_vm.showDateAndLocation)?_c('div',{staticClass:"text-gray-500 group-hover:text-white group-active:text-white duration-300 transition flex flex-wrap gap-1 mb-2",class:_vm.columns === '2' ? 'flex-row' : 'flex-col'},[(_vm.date)?_c('span',{staticClass:"flex flex-nowrap gap-2 items-start text-sm"},[(_vm.location)?_c('BaseIcon',{attrs:{"name":"calendar-days","type":"light","classes":"py-[3px] text-black group-hover:text-white duration-300 transition h-5"}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.date)+"\n          ")])],1):_vm._e(),_vm._v(" "),(_vm.location)?_c('span',{staticClass:"flex flex-nowrap gap-2 items-start text-sm"},[(_vm.location)?_c('BaseIcon',{attrs:{"name":"location-dot","type":"light","classes":"py-[3px] text-black group-hover:text-white duration-300 transition h-5"}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.location)+"\n          ")])],1):_vm._e(),_vm._v(" "),(_vm.tags)?_c('span',{staticClass:"text-gray-500 text-sm group-hover:text-white mr-1 flex-shrink-0 duration-300 transition"},[_vm._v("\n          "+_vm._s(_vm.tags)+"\n        ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.headline)?_c('header',{staticClass:"font-bold mb-2 break-words",class:_vm.headlineClasses},[_vm._v("\n        "+_vm._s(_vm.headline)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.text)?_c('div',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")]):_vm._e(),_vm._v(" "),_c('BaseLink',_vm._b({staticClass:"mt-auto w-max group-hover:text-white group-active:text-white",attrs:{"inline":false,"target":"_blank","tag":"span"}},'BaseLink',_vm.link,false))],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }