
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IHeaderNavElement from '../../shared/general/interfaces/IHeaderNavElement'

@Component({ name: 'TheSeoNavigation' })
export default class TheSeoNavigation extends Vue {
  @Prop({ default: () => [] }) entry! : IHeaderNavElement[]

  @Prop() label ?: string

  @Prop() path ?: string
}
