
import { FSXABaseComponent } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import LoaderSvg from '../svgs/LoaderSvg.vue'

@Component({
  name: 'Loader',
  components: { LoaderSvg },
})
export default class Loader extends FSXABaseComponent {
  destroyed () {
    window.dispatchEvent(new CustomEvent('loader-unmounted'))
  }
}
