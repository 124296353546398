
import { Component, mixins, Watch } from 'nuxt-property-decorator'
import { MetaInfo } from 'vue-meta'
import { RichTextElement } from 'fsxa-api'
import BaseIconButton from '../../base/BaseIconButton.vue'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import BasePageMetaTags from './BasePageMetaTags.vue'
import BasePageTitle from './BasePageTitle.vue'
import BasePageLinks from './BasePageLinks.vue'
import BasePageSocialSharing from './BasePageSocialSharing.vue'
import IBreadcrumbItem from '../../../shared/general/interfaces/IBreadcrumbItem'
import TheBreadcrumb from '../../layouts/TheBreadcrumb.vue'
import MessageBar from '../../MessageBar.vue'
import { ISocialSharingData } from '../../../store/SocialSharing'
import BaseToolbarWrapper from '../../base/toolbar/BaseToolbarWrapper.vue'
import BaseHeadline from '../../base/BaseHeadline.vue'
import TeaserRcWrapper from '../../teaser/rc/TeaserRcWrapper.vue'
import IBaseMetaData from '../../../shared/general/interfaces/IBaseMetaData'
import { globalLabel } from '../../../shared/general/services/StoreService'
import ILocationData, { ILocationTypeData } from '../../../shared/fsxa/interfaces/ILocationData'
import IReference from '../../../shared/fsxa/interfaces/IReference'
import { Logger } from '../../../shared/general/logger/Logger'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import fsxaProxyApiRemote from '../../../shared/fsxa/services/FsxaProxyApiRemote'
import { TLocationSearchView } from '../../../shared/fsxa/types/TView'
import { IMessage } from '../../../shared/general/interfaces/IMessage'
import { TMessageBarColor } from '../../../shared/general/types/TMessageBarColor'
import { TIcon } from '../../../shared/general/types/TIcon'
import PageNotTranslated from '../PageNotTranslated.vue'

@Component({
  name: 'BasePage',
  components: {
    BaseGridLayout,
    TheBreadcrumb,
    MessageBar,
    BaseIconButton,
    BaseToolbarWrapper,
    TeaserRcWrapper,
    BaseHeadline,
    PageNotTranslated,
  },
})
export default class BasePage extends mixins(BasePageTitle, BasePageMetaTags, BasePageLinks, BasePageSocialSharing) {
  private pageData : IBaseMetaData = {}

  protected messageBarText : string | RichTextElement[] = ''

  protected messageBarColor : TMessageBarColor = 'green'

  protected messageBarIconName : string = ''

  protected messageBarIconType : TIcon = 'light'

  created () {
    this.pageData = this.metaData
    this.storePageData()
    this.$store.commit('SocialSharing/setState', this.socialShareData)
    this.checkAnchorLink()
  }

  async mounted () {
    await this.fetchRemoteDatasetsBasePage()
    this.setLocationType()
    this.setLocationSidebarState()
    await this.setMessageBarState()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasetsBasePage()
  }

  private async setMessageBarState () : Promise<void> {
    const locationType = this.locationTypeRemote?.data?.tt_key
    const savedLocations = await this.$store.dispatch('Locations/getSavedLocationTypes')
    if (locationType && savedLocations.length && !savedLocations.includes(locationType)) {
      this.$store.commit('MessageBar/set', {
        color: 'yellow',
        text: globalLabel('service_not_available_at_location_label'),
        iconName: 'circle-info',
        iconType: 'solid',
      })
    }
  }

  private async fetchRemoteDatasetsBasePage () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.locationTypeRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.locationSidebarLocationViewRemoteDatasetIndex),
    ])
  }

  /**
   * @param checkToolbar additional flag to enable check (should the link be displayed inside the toolbar)
   */
  public async getOnlineAppointmentLink (checkToolbar : boolean = false) : Promise<string> {
    if (!this.data.pt_use_location_reference) return ''
    if (checkToolbar && !this.data.pt_show_onlineappointment) return ''

    const locationType = this.locationTypeRemote?.data?.tt_key
    if (!locationType) return ''
    const savedLocationId = await this.$store.dispatch('Locations/getSavedLocationId', locationType)
    if (!savedLocationId) return ''

    try {
      const location : ILocationData | undefined = await fsxaProxyApiRemote.fetchElement({
        id: savedLocationId,
        locale: this.$store.state.Locale.fsxaLocale,
      })
      // online appointment link is always 'string' (external link)
      const link : string | IReference | undefined = location?.data?.tt_link_online_appointment?.data?.lt_link
      if (link && typeof link === 'string') return link
    } catch (e) {
      Logger.warn(`Unable to fetch online appointment link, location was ${savedLocationId}`, e)
    }

    return ''
  }

  @Watch('$store.state.Locations.savedLocations', { deep: true, immediate: true })
  private async savedLocationChange (savedLocations : Record<string, string>) : Promise<void> {
    const savedLocationTypeKeys = Object.keys(savedLocations)
    const locationType = this.locationTypeRemote?.data?.tt_key
    if (locationType && savedLocationTypeKeys.length && !savedLocationTypeKeys.includes(locationType)) {
      this.$store.commit('MessageBar/set', {
        color: 'yellow',
        text: globalLabel('service_not_available_at_location_label'),
        iconName: 'circle-info',
        iconType: 'solid',
      })
    } else {
      this.$store.commit('MessageBar/reset')
    }
  }

  protected isTranslated () : boolean {
    // When the content projection is a 'service' and we are in the preview we allow to show the content
    if (this.currentDataset?.entityType === 'service' && this.$config.FSXA_MODE === 'preview') return true
    return !!this.currentPage?.customData?.translated
  }

  private setLocationSidebarState () : void {
    this.$store.commit('ToolbarElements/setLocationSidebarProps', {
      showLocationSidebarElement: (!!this.data.pt_use_location_reference && !!this.locationSidebarLocationViewRemote
          && !!this.locationTypeRemote?.id),
      locationSidebarView: this.locationSidebarLocationViewRemote,
      locationSidebarType: {
        id: this.locationTypeRemote?.id,
        label: this.locationTypeRemote?.data?.tt_name || '',
        value: this.locationTypeRemote?.data?.tt_key || '',
      },
    })
  }

  private get locationSidebarLocationViewRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.globalSettings?.data?.ps_country_project_settings?.pt_location_sidebar_location_view_remote
  }

  private get locationSidebarLocationViewRemote () : TLocationSearchView | undefined {
    return getRemoteDatasetsFromStore(this.locationSidebarLocationViewRemoteDatasetIndex)[0]
  }

  get locationTypeRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.data.pt_location_type_remote
  }

  get locationTypeRemote () : ILocationTypeData | undefined {
    return getRemoteDatasetsFromStore(this.locationTypeRemoteDatasetIndex)[0]
  }

  get headline () : string {
    return this.isContentProjectionPage ? '' : this.data.pt_headline || ''
  }

  get breadcrumbItems () : IBreadcrumbItem[] {
    return this.$store.state.Breadcrumbs.items
  }

  get socialShareData () : ISocialSharingData {
    return {
      displaySocialShareBar: !!this.pageData.displaySocialShareBar,
      url: window.location.href,
      title: this.pageData.ogTitle,
      description: this.pageData.ogDescription,
      hashtags: this.pageData.twitterCard,
      twitterUser: this.pageData.twitterSite,
      media: this.pageData.ogImg?.resolutions?.ORIGINAL?.url,
    }
  }

  head () : MetaInfo {
    if (process.client) window.dispatchEvent(new CustomEvent('titleUpdated', { detail: { title: this.pageTitle } }))

    return {
      title: this.pageTitle,
      meta: this.metaTags,
      link: this.links,
    }
  }

  private storePageData () : void {
    if (this.data.pt_use_location_reference === undefined || this.data.pt_use_location_reference === null) return
    this.$store.commit('Page/setUseLocationReference', this.data.pt_use_location_reference)
  }

  private setLocationType () : void {
    if (!this.locationTypeRemote?.data?.tt_key) return
    this.$store.commit('Page/setLocationType', this.locationTypeRemote.data.tt_key)
  }

  checkAnchorLink () : void {
    if (!this.data.pt_anchor_links?.value?.length) return
    this.$store.commit('AnchorLinks/setAnchorLinks', this.data.pt_anchor_links.value)
  }

  @Watch('$store.state.MessageBar.message', { deep: true })
  private textChanged (message : IMessage) : void {
    this.messageBarColor = message.color
    this.messageBarIconName = message.iconName || ''
    this.messageBarIconType = message.iconType || 'light'
    this.messageBarText = message.text
  }

  protected messageBarClick () : void {
    this.messageBarText = ''
  }
}
