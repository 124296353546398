
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface IPayload {
  st_key_message ?: RichTextElement[]
}

@Component({
  name: 'StKeyMessage',
  components: {
    BaseGridLayout,
    KeyMessage: () => import('../../base/KeyMessage.vue'),
  },
})
export default class StKeyMessage extends FSXABaseSection<IPayload> {
  private get messages () : RichTextElement[] {
    return this.payload.st_key_message || []
  }
}
