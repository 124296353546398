import IImage from '../general/interfaces/IImage'

export interface ISmartSearchMediaHost {
  mediaHost ?: string
  projectId ?: string
  remotes ?: any
}

const removeLeadingSlash = (value : string = '') : string => (value.startsWith('/') ? value.slice(1) : value)

const addTrailingSlash = (value : string = '') : string => (value.endsWith('/') ? value : `${value}/`)

const absoluteImageURL = (
  host : string,
  projectId : string,
  path : string,
) : string => `${addTrailingSlash(host)}${addTrailingSlash(projectId)}${removeLeadingSlash(path)}`

export default (smartSearchMediaHost : ISmartSearchMediaHost, teaserImage ?: string, templateUid ?: string, altText ?: string, decorative ?: string)
  : IImage | undefined => {
  if (!teaserImage?.length) return undefined

  const hasRemoteProjectId = (json : any) : boolean => !!json?.media?.id

  // as we don't have any host in front of the URL from the SmartSearch we need to add it manually
  const getImageURL = (url ?: string) : string => {
    if (!url?.length) return ''
    const { mediaHost, projectId } : { mediaHost ?: string, projectId ?: string } = smartSearchMediaHost

    if (!mediaHost || !projectId) return ''
    if (url.startsWith('http')) return url
    if (templateUid?.includes('local_data')) return absoluteImageURL(mediaHost, projectId, url)

    const { remotes } = smartSearchMediaHost
    if (!hasRemoteProjectId(remotes)) return ''
    const remoteProjectID : string = remotes.media.id
    return absoluteImageURL(mediaHost, remoteProjectID, url)
  }

  const isIImage = (object : any) : object is IImage => {
    if (!object) return false
    return 'resolutions' in object && Array.isArray(object.resolutions)
        && object.resolutions.some((resolution : any) => !!resolution.url)
  }

  // the data in a SmartSearch field is string and image here is a string in JSON format and needs to be parsed.
  const parsed : any = JSON.parse(teaserImage)
  if (!isIImage(parsed)) {
    // eslint-disable-next-line no-console
    console.error('unable to parse image: JSON format is not correct', teaserImage)
    return undefined
  }

  return {
    ...parsed,
    altText: decorative?.includes('true') ? undefined : altText,
    resolutions: parsed.resolutions.map((resolution) => ({
      ...resolution,
      url: getImageURL(resolution.url),
    })),
  }
}
