
import { RichTextElement } from 'fsxa-api'
import { Component, Vue, Watch } from 'nuxt-property-decorator'

interface Message {
  text : string | RichTextElement[]
  visible : boolean
  timeout : number
}

@Component({
  name: 'TheSnackbar',
  components: { GlobalLabelWrapper: () => import('./GlobalLabelWrapper.vue') },
})
export default class TheSnackbar extends Vue {
  private messages : Message[] = []

  private hide (message : Message) : void {
    message.timeout = window.setTimeout(() => {
      message.visible = false
      setTimeout(() => this.messages.splice(this.messages.indexOf(message), 1), 500)
    }, 4000)
  }

  private get lastMessage () : Message | undefined {
    return this.messages[this.messages.length - 1]
  }

  @Watch('$store.state.Snackbar.text')
  private textChanged (newText : string | RichTextElement[]) : void {
    const newMessage : Message = {
      text: newText,
      visible: true,
      timeout: 0,
    }

    const oldMessages = [...this.messages]
    oldMessages.forEach((message) => {
      clearTimeout(message.timeout)
      message.visible = false
    })

    setTimeout(() => {
      oldMessages.forEach((message) => {
        const index = this.messages.indexOf(message)
        if (index !== -1) this.messages.splice(index, 1)
      })
    }, 500)

    this.messages.push(newMessage)
    this.hide(newMessage)
  }
}
