
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import IHeaderNavElement from '../../shared/general/interfaces/IHeaderNavElement'
import ELinkTarget from '../../shared/general/enums/ELinkTarget'
import isExternalURL from '../../shared/fsxa/services/ExternalLinkService'
import { ILink } from '../../shared/general/interfaces/ILink'

@Component({
  name: 'BaseNavigationLevel',
  components: {
    BaseIcon: () => import('./BaseIcon.vue'),
    BaseLink: () => import('./BaseLink.vue'), // used in navElementProps & header getter
  },
})
export default class BaseNavigationLevel extends Vue {
  @Prop() current ?: IHeaderNavElement

  @Prop({ default: () => [] }) children! : IHeaderNavElement[]

  @Prop({ default: 'navigation' }) source! : 'navigation' | 'burger'

  private get header () {
    const is = this.current?.path ? 'BaseLink' : 'p'
    const url = this.current?.path ?? ''

    return {
      is,
      url,
      internal: !isExternalURL(url),
      onlySlotContent: true,
    }
  }

  private navElementProps (navEntry : IHeaderNavElement) {
    const hasChildren = this.hasChildren(navEntry)
    const is = hasChildren ? 'li' : 'BaseLink'
    const url = hasChildren ? '' : navEntry.path
    const internal = url ? !isExternalURL(url) : true

    const result = {
      is,
      url,
      target: internal ? ELinkTarget.Self : ELinkTarget.Blank,
    } as ILink

    if (!hasChildren) {
      result.onlySlotContent = true
    }

    return result
  }

  private hasChildren (navEntry : IHeaderNavElement) : boolean {
    return !!navEntry.children?.length
  }

  @Emit('click-navigation-element')
  private click (child : IHeaderNavElement) : IHeaderNavElement {
    return child
  }

  @Emit('click-header-element')
  private clickHeader (header : IHeaderNavElement) : IHeaderNavElement {
    return header
  }
}
