
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import TrimmedLogo from '../svgs/TrimmedLogo.vue'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import { ILink } from '../../shared/general/interfaces/ILink'
import { globalLabel, globalLabelAsString } from '../../shared/general/services/StoreService'

@Component({
  name: 'FooterUpperSection',
  components: {
    BaseGridLayout,
    TrimmedLogo,
    BaseLink: () => import('../base/BaseLink.vue'),
    GlobalLabelWrapper: () => import('../GlobalLabelWrapper.vue'),
  },
})
export default class FooterUpperSection extends Vue {
  @Prop({ required: true }) footerSocialMediaElements! : ILink[]

  private get globalLabelDekraName () : string | RichTextElement[] {
    return globalLabel('dekra')
  }

  private get globalLabelDekraClaim () : string | RichTextElement[] {
    return globalLabel('dekra_claim')
  }

  private get globalLabelFollowUs () : string {
    return `${globalLabelAsString('follow_us_label')}:`
  }
}
