import IImageVideoContent from '../interfaces/IImageVideoContent'
import { IGalleryContent } from '../interfaces/IGalleryContent'
import StoreSingleton from './StoreSingleton'

export const openLightbox = (content : IImageVideoContent) : void => {
  const store = StoreSingleton.instance
  store.commit('ModalContent/setOpen', true)
  store.commit('ModalContent/setContent', {
    component: 'ImageVideoModalContent',
    props: {
      content,
    },
  })
}

export const openLightboxCarousel = (elements : IGalleryContent[], startIndex : number) : void => {
  const store = StoreSingleton.instance
  store.commit('ModalContent/setOpen', true)
  const modalContent = {
    component: 'LightboxCarousel',
    props: {
      elements,
      startIndex,
    },
  }
  store.commit('ModalContent/setContent', modalContent)
}
