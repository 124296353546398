
import { Component, Watch } from 'nuxt-property-decorator'
import BasePage from '../base/BasePage.vue'
import LifespanAwareContentWrapper from '../wrappers/LifespanAwareContentWrapper'

@Component({
  name: 'PtOverviewPage',
  components: { LifespanAwareContentWrapper },
})
export default class PtOverviewPage extends BasePage {
  @Watch('$store.state.Locations.savedLocations', { deep: true, immediate: true })
  private async savedLocationsChange () : Promise<void> {
    this.$store.commit('LocationOnlineAppointment/set', await this.getOnlineAppointmentLink())
    this.$store.commit('ToolbarElements/setOnlineAppointmentLink', await this.getOnlineAppointmentLink(true))
  }
}

