
import { FSXABaseSection, FSXARichText } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface IPayload {
  st_infobox_headline ?: string
  st_infobox_text ?: FSXARichText
  st_show_icon ?: boolean
  st_background_grey ?: boolean
}

@Component({
  name: 'StInfobox',
  components: {
    'rich-text': FSXARichText,
    Infobox: () => import('../../Infobox.vue'),
    BaseGridLayout,
  },
})
export default class StInfobox extends FSXABaseSection<IPayload> {
  private get headline () : string | undefined {
    return this.payload.st_infobox_headline?.trim()
  }

  private get showIcon () : boolean | undefined {
    return this.payload.st_show_icon
  }

  private get coloredBackground () : boolean | undefined {
    return this.payload.st_background_grey
  }

  private get content () : FSXARichText | undefined {
    return this.payload.st_infobox_text
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
