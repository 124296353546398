const getURLParameterValue = (name : string) : string => {
  if (!window) return ''
  return new URLSearchParams(window.location.search).get(name) || ''
}

const getURLParameters = <T extends string[]>(names : [...T]) : { [Key in T[number]] : string } => {
  if (!window) return names.reduce((previous, current) => ({ ...previous, [`${current}`]: '' }), {}) as { [Key in T[number]] : string }
  const searchParams : URLSearchParams = new URLSearchParams(window.location.search)
  return names.reduce((previous, current) => ({ ...previous, [`${current}`]: searchParams.get(current) }), {}) as { [Key in T[number]] : string }
}

const setURLParameterValue = (name : string, value ?: string) : void => {
  if (!window) return

  const searchParams : URLSearchParams = new URLSearchParams(window.location.search)
  if (!value) searchParams.delete(name)
  else searchParams.set(name, value)

  if (searchParams.toString().length > 1) {
    window.history.pushState(null, '', `${window.location.pathname}?${searchParams.toString()}`)
  } else {
    window.history.pushState(null, '', `${window.location.pathname}`)
  }
}

export { getURLParameterValue, setURLParameterValue, getURLParameters }
