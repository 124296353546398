export default (text ?: string, length : number = 100, ellipsis : boolean = true, separator : string = ' ') : { original : string, cut : string } => {
  if (!text) return { original: '', cut: '' }
  if (text.length <= length) {
    return {
      original: text,
      cut: text,
    }
  }

  const words = text.split(separator)
  const result : string[] = []

  for (let i = 0; i < words.length; i += 1) {
    const word = words[i]
    if (result.join(separator).length + word.length + 1 > length) {
      return {
        original: text,
        cut: `${result.join(separator)}${ellipsis ? '…' : ''}`,
      }
    }
    result.push(word)
  }

  return {
    original: text,
    cut: '',
  }
}
