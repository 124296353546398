
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import IImage from '../../shared/general/interfaces/IImage'
import IImageVideoContent from '../../shared/general/interfaces/IImageVideoContent'
import IVideo from '../../shared/general/interfaces/IVideo'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import { getVideoUrl } from '../../shared/general/services/VideoUrlService'
import { setOne } from '../../shared/general/services/privacy/PrivacySettingsService'

@Component({
  name: 'BaseImageVideo',
  components: {
    BasePicture: () => import('../base/BasePicture.vue'),
    BaseVideo: () => import('../base/BaseVideo.vue'),
    BaseHtmlPlayer: () => import('../base/BaseHtmlPlayer.vue'),
    BaseIcon: () => import('../base/BaseIcon.vue'),
    CookieLayer: () => import('../CookieLayer.vue'),
  },
})

export default class BaseImageVideo extends Vue {
  @Prop({ required: true }) mediaType! : 'image' | 'video'

  @Prop({ required: true }) image! : IImage

  @Prop() lightboxImage ?: IImage

  @Prop() video ?: IVideo

  @Prop() caption ?: string

  @Prop() title ?: string

  @Prop({ default: false }) lightbox! : boolean

  @Prop({ default: false }) inlineVideo! : boolean

  @Prop() aspectRatio ?: string

  @Prop({ default: false }) forceRatio! : boolean

  @Prop() breakpoints ?: Record<number, string | undefined>

  private showCookieLayer : boolean = true

  private cssAspectRatio : Record<string, string> = {
    '3:4': 'aspect-3/4',
    '4:3': 'aspect-4/3',
    '16:9': 'aspect-video',
    '9:16': 'aspect-9/16',
    '1:1': 'aspect-square',
    undefined: '',
  }

  private clickableContent : IImageVideoContent | null = null

  private contentDefault : IImageVideoContent = {
    tag: 'BasePicture',
    props: {
      image: this.image,
      breakpoints: this.breakpoints,
    },
  }

  mounted () {
    this.clickableContent = this.contentDefault
    this.showCookieLayer = this.checkShowCookieLayer()
  }

  @Watch('$store.state.PrivacySettings.settings.youtube')
  @Watch('$store.state.PrivacySettings.settings.vimeo')
  @Watch('$store.state.PrivacySettings.settings.tencent')
  private onVideoConsentChange () {
    this.showCookieLayer = this.checkShowCookieLayer()
  }

  private get youtubeCookiesAccepted () : boolean {
    return !!this.$store.state.PrivacySettings.settings.youtube
  }

  private get vimeoCookiesAccepted () : boolean {
    return !!this.$store.state.PrivacySettings.settings.vimeo
  }

  private get tencentCookiesAccepted () : boolean {
    return !!this.$store.state.PrivacySettings.settings.tencent
  }

  private acceptCookies () : void {
    if (!this.video || this.video.format === 'html5') return
    setOne(this.video.format, true)
  }

  private openVideoExternal () : void {
    if (!this.video?.id) return

    const url = getVideoUrl(this.video.id, this.video.format, true)
    if (url) window.open(url, '_blank')
  }

  private get noVideoAcceptLabel () : string {
    return globalLabelAsString('accept_cookies')
  }

  private get externalButtonAcceptLabel () : string {
    return globalLabelAsString('open_video_external_label')
  }

  private get blurStyle () : string {
    return this.showCookieLayer && this.mediaType === 'video' ? 'blur-sm pointer-events-none' : ''
  }

  private get actualContent () : IImageVideoContent {
    if (this.mediaType === 'image') {
      return {
        tag: 'BasePicture',
        props: { image: this.lightboxImage || this.image },
      }
    }

    if (!this.video?.id?.length || this.video.format === 'html5') {
      return {
        tag: 'BaseHtmlPlayer',
        props: {
          htmlPlayerElements: this.video?.htmlPlayerElements,
        },
      }
    }

    return {
      tag: 'BaseVideo',
      props: {
        id: this.video.id,
        title: this.video.title,
        format: this.video.format,
        privacyHash: this.video.privacyHash,
      },
    }
  }

  private checkShowCookieLayer () : boolean {
    if (!process.client) return true

    if (!this.video?.id) return false
    if (!this.video?.format) return false
    if (this.video.format === 'html5') return false

    switch (this.video.format) {
      case 'youtube':
        return !this.youtubeCookiesAccepted
      case 'vimeo':
        return !this.vimeoCookiesAccepted
      case 'tencent':
        return !this.tencentCookiesAccepted
      default:
        return false
    }
  }

  @Emit('click')
  private click () : IImageVideoContent {
    if (this.mediaType === 'video' && this.inlineVideo) {
      this.clickableContent = this.actualContent
    }

    return this.actualContent
  }
}
