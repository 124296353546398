
import { Component } from 'nuxt-property-decorator'
import TeaserEventsHomeOverview from '../../teaser/events/TeaserEventsHomeOverview.vue'
import BaseTeaserEventsSection from '../base/BaseTeaserEventsSection.vue'

@Component({
  name: 'StTeaserEventsHome',
  components: { TeaserEventsHomeOverview },
})
export default class StTeaserEventsHome extends BaseTeaserEventsSection {
  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
