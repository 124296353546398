
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import IOption from '../../shared/general/interfaces/IOption'
import IHeaderNavElement from '../../shared/general/interfaces/IHeaderNavElement'
import HeaderUpperSection from './HeaderUpperSection.vue'
import HeaderLowerSection from './HeaderLowerSection.vue'
import HeaderSearchComponent from './HeaderSearchComponent.vue'
import TheNavigation from '../layouts/TheNavigation.vue'
import TheSeoNavigation from '../layouts/TheSeoNavigation.vue'
import TheBurgerMenu from '../layouts/TheBurgerMenu.vue'
import { resetActiveState } from '../../shared/general/services/NavigationMenuService'
import isExternalURL from '../../shared/fsxa/services/ExternalLinkService'

@Component({
  name: 'TheHeader',
  components: {
    HeaderUpperSection,
    HeaderLowerSection,
    HeaderSearchComponent,
    TheNavigation,
    TheSeoNavigation,
    TheBurgerMenu,
  },
})
export default class TheHeader extends Vue {
  @Prop({ required: true }) headerNavElements! : IHeaderNavElement[]

  @Prop({ required: true }) searchPageUrl! : string

  @Prop({ required: true }) languages! : IOption[]

  $refs! : {
    headerSearchMobile : HeaderSearchComponent
    headerSearchDesktop : HeaderSearchComponent
    desktopNavigation : TheNavigation
  }

  private navElements : IHeaderNavElement[] = []

  private currentNavEntry : IHeaderNavElement | null = null

  private isDesktopNavigationOpen : boolean = false

  private isSearchbarVisible : boolean = false

  private isHamburgerNavigationOpen : boolean = false

  private useBurgerMenu : boolean = false

  private lastScroll : number = 0

  created () {
    this.navElements = this.headerNavElements
  }

  mounted () {
    this.animateHeaderOnScroll()
  }

  private get searchbarStyles () : { desktop : string, mobile : string } {
    return {
      mobile: this.isSearchbarVisible ? 'translate-y-0' : '-translate-y-full',
      desktop: this.isSearchbarVisible ? 'scale-x-100' : 'scale-x-0',
    }
  }

  private get layerVisible () : boolean {
    return this.isHamburgerNavigationOpen || this.isDesktopNavigationOpen || this.isSearchbarVisible
  }

  private get darkLayerStyles () : string {
    const styles : string[] = ['-z-10']
    styles.push(this.isHamburgerNavigationOpen ? 'top-0' : 'top-1')

    if (!this.isHamburgerNavigationOpen && !this.isDesktopNavigationOpen && !this.isSearchbarVisible) {
      styles.push('h-0')
      styles.push('opacity-0')
    } else {
      styles.push('h-screen')
      styles.push('opacity-80')
    }

    if (this.isSearchbarVisible) {
      styles.push('h-screen')
      styles.push('opacity-80')
    } else if (this.useBurgerMenu) {
      styles.push(this.isHamburgerNavigationOpen ? 'opacity-80 z-40' : 'opacity-0 h-0')
    } else {
      styles.push(this.isDesktopNavigationOpen ? 'opacity-80' : 'opacity-0')
    }

    return styles.join(' ')
  }

  private closeFilter () : void {
    this.$store.commit('ModalContent/setFilterOpen', false)
  }

  private isSafariBrowser () : boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  private closeFilters () : void {
    this.$store.commit('ModalContent/setIsIsHeaderClicked', true)
  }

  private animateHeaderOnScroll () : void {
    const header = document.getElementById('header')
    if (!header) return
    window.addEventListener('scroll', () => this.scrollListener(header))
  }

  private scrollListener (header : HTMLElement) : void {
    const currentScroll = window.scrollY

    if (this.isSafariBrowser()) {
      // Workaround for Safari bug when scrolling to the top
      if ((window.innerHeight + window.scrollY) < window.innerHeight) {
        return
      }

      // Workaround for Safari bug when scrolling to the bottom
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        return
      }
    }

    if (!this.layerVisible && currentScroll > this.lastScroll && currentScroll > 88 && !header.classList.contains('scroll-down')) {
      header.classList.add('scroll-down')
    }

    if (currentScroll < this.lastScroll && header.classList.contains('scroll-down')) {
      header.classList.remove('scroll-down')
    }

    this.lastScroll = currentScroll
  }

  private receiveUseBurgerMenu (useBurgerMenu : boolean) : void {
    if (useBurgerMenu) {
      if (this.isDesktopNavigationOpen) {
        this.toggleDesktopNavigation(false)
      }
    } else if (this.isHamburgerNavigationOpen) {
      this.toggleHamburgerNavigation(false)
    }
    this.useBurgerMenu = useBurgerMenu
  }

  private toggleSearchbar () : void {
    this.isSearchbarVisible = !this.isSearchbarVisible

    if (this.isSearchbarVisible) {
      this.toggleDesktopNavigation(false)
      this.$refs.headerSearchDesktop?.focusInput()
      this.$refs.headerSearchMobile?.focusInput()
    }
  }

  @Emit('select-language')
  private selectLanguage (language : IOption) : IOption {
    return language
  }

  private clickNavElement (navElement : IHeaderNavElement) : void {
    if (!navElement.children?.length) {
      if (isExternalURL(navElement.path)) return

      this.$router.push(navElement.path)
      this.toggleDesktopNavigation(false)
      return
    }
    if (this.currentNavEntry === navElement) {
      this.toggleDesktopNavigation()
      return
    }

    if (this.currentNavEntry) {
      this.currentNavEntry.isActive = false
    }
    this.currentNavEntry = navElement
    this.toggleDesktopNavigation(true)
  }

  private toggleDesktopNavigation (open ?: boolean) : void {
    this.isDesktopNavigationOpen = open === undefined ? !this.isDesktopNavigationOpen : open
    if (this.currentNavEntry) {
      this.currentNavEntry.isActive = this.isDesktopNavigationOpen
      this.navElements = [...this.navElements]
    }
    this.toggleDarkLayer(this.isDesktopNavigationOpen)
    if (!this.isDesktopNavigationOpen) {
      this.navElements = resetActiveState(this.navElements)
      const nav = this.$refs.desktopNavigation as TheNavigation
      const newNavTree = [...nav.tree]
      newNavTree.length = nav.tree.length > 0 ? 1 : 0
      nav.tree = newNavTree
      nav.reset()
    }
  }

  private toggleHamburgerNavigation (open ?: boolean) : void {
    this.isHamburgerNavigationOpen = open === undefined ? !this.isHamburgerNavigationOpen : open
    this.toggleDarkLayer(this.isHamburgerNavigationOpen)
    if (!this.isHamburgerNavigationOpen) {
      this.navElements = resetActiveState(this.navElements)
    }
  }

  private closeBothNavigations () : void {
    this.toggleDesktopNavigation(false)
    this.toggleHamburgerNavigation(false)
    this.closeSearchBar()
  }

  private search (value : string) : void {
    if (!value.length || !this.searchPageUrl) return

    const normalizePath = (path : string) => (path.endsWith('/') ? path.slice(0, path.length - 1) : path)
    const searchPagePath = normalizePath(this.searchPageUrl)
    const currentPath = normalizePath(this.$route.path)

    this.closeSearchBar()
    const url : string = `${window.location.origin}${searchPagePath}/?q=${value}`
    if (currentPath === searchPagePath) {
      window.history.pushState({}, document.title, url)
      this.$store.dispatch('SmartSearch/triggerSearchResultSearch', value)
    } else {
      window.location.href = url
    }
  }

  private closeSearchBar () : void {
    this.isSearchbarVisible = false
  }

  private toggleDarkLayer (open : boolean) : void {
    const func = open ? 'add' : 'remove'
    document.documentElement.classList[func]('overflow-hidden')
  }

  @Watch('isDesktopNavigationOpen')
  private openStageChange (state : boolean) : void {
    this.$store.commit('Page/setIsNavigationOpen', state)
  }
}
