import ITeaser from '../interfaces/ITeaser'
import { ITeaserData } from '../../general/interfaces/ITeaserData'
import { mapImage } from '../../general/services/ImageService'
import { globalLabelAsString } from '../../general/services/StoreService'
import { formatJoinFromToDate } from '../../general/services/DateTimeService'
import { mapTags } from './TagService'
import { getRemoteDatasetsFromStore } from './RemoteDatasetService'

const sort = (a : ITeaser, b : ITeaser, order : 'asc' | 'desc') : number => {
  if (!a.data?.tt_publish_from) return -1
  if (!b.data?.tt_publish_from) return 1
  const dateA : Date = new Date(a.data.tt_publish_from)
  const dateB : Date = new Date(b.data.tt_publish_from)
  if (order === 'asc') return dateA.getTime() - dateB.getTime()
  return dateB.getTime() - dateA.getTime()
}
export const sortDescending = (a : ITeaser, b : ITeaser) : number => sort(a, b, 'desc')

export const mapToTeaserData = (teaser : ITeaser) : ITeaserData => ({
  headline: teaser.data?.tt_headline || '',
  text: teaser.data?.tt_teaser_text || '',
  image: mapImage(
    teaser.data?.tt_teaser_image,
    teaser.data?.tt_teaser_image_alt_text,
    teaser.data?.tt_teaser_image_alt_text,
    teaser.data?.tt_teaser_image_decorative,
  ),
  mediaType: teaser.data?.tt_teaser_image ? 'image' : 'text',
  teaserVariant: 'vertical',
  link: {
    url: teaser.route || teaser.routes?.[0]?.route,
    label: globalLabelAsString('specific_article_link_label'),
    iconName: 'chevron-right',
  },
  date: formatJoinFromToDate({ from: teaser.data?.tt_publish_from }),
  tags: mapTags(teaser.data?.tt_tags_remote ? getRemoteDatasetsFromStore(teaser.data.tt_tags_remote) : teaser.data?.tt_tags).join(' / '),
  aspectRatio: '16:9',
  forceRatio: true,
})
