
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseLink from '../base/BaseLink.vue'
import BaseIconText from '../base/BaseIconText.vue'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import { ILink } from '../../shared/general/interfaces/ILink'

@Component({
  name: 'FooterLowerSection',
  components: {
    BaseLink,
    BaseIconText,
    BaseGridLayout,
  },
})
export default class FooterLowerSection extends Vue {
  @Prop({ required: true }) footerNavLinks! : ILink[]

  private get siteIdentifier () : string {
    return this.$store.state.PageHeader.siteIdentifier
  }
}
