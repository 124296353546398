
import { Component, Vue } from 'nuxt-property-decorator'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import BaseLink from '../base/BaseLink.vue'

@Component({
  name: 'HeaderDekraLogo',
  components: { BaseLink },
})
export default class HeaderDekraLogo extends Vue {
  private get homepageUrl () : string {
    return this.$store.state.PageHeader.homepage
  }

  private get logoLabel () : string {
    return globalLabelAsString('logo_label')
  }
}
