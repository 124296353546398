
import { Section } from 'fsxa-api'
import { FSXABaseSection, FSXARenderContentElement } from 'fsxa-pattern-library'
import { Component, Provide } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface IPayload {
  st_column ?: {
    identifier ?: '1' | '2' | '3'
  }
  st_content ?: Section[]
}

@Component({
  name: 'StContainer',
  components: {
    BaseGridLayout,
    FSXARenderContentElement,
  },
})
export default class StContainer extends FSXABaseSection<IPayload> {
  @Provide('containerColumns') containerColumns : '1' | '2' | '3' = this.numberOfColumns

  private colSpan : Record<string, string> = {
    1: 'col-span-12',
    2: 'col-span-full sm:col-span-4 md:col-span-6 grid-flow-row',
    3: 'col-span-4 grid-flow-row',
  }

  private get getContent () : Section[] {
    return this.payload.st_content || []
  }

  private get numberOfColumns () : '1' | '2' | '3' {
    return this.payload.st_column?.identifier || '1'
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
