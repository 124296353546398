
import { Flicking, FlickingOptions } from '@egjs/vue-flicking'
import {
  Component, Prop, Vue,
} from 'nuxt-property-decorator'
import { IGalleryContent } from '../shared/general/interfaces/IGalleryContent'
import BaseIconButton from './base/BaseIconButton.vue'
import BaseImageVideo from './ImageVideo/BaseImageVideo.vue'

@Component({
  name: 'LightboxCarousel',
  components: {
    Flicking,
    BaseIconButton,
    BaseImageVideo,
  },
})
export default class LightboxCarousel extends Vue {
  @Prop({ required: true }) elements! : IGalleryContent[]

  @Prop({ default: 0 }) startIndex! : number

  private animating : boolean = false

  private key : number = 0

  private staticFlickingOptions = {
    horizontal: true,
    circularFallback: 'linear',
    circular: true,
    panelsPerView: 1,
    align: 'prev',
  }

  private get flickingOptions () : FlickingOptions {
    return {
      ...this.staticFlickingOptions,
      defaultIndex: this.startIndex,
    } as FlickingOptions
  }

  private slide (direction : string) : void {
    if (!direction || this.animating) return

    this.animating = true;
    (this.$refs.carousel as Flicking)?.[direction]()
  }

  private moveEnd () : void {
    this.animating = false

    // to force rerendering the BaseImageVideo component to stop playing videos
    this.key += 1
    this.$el.classList.remove('playing')
  }

  private hideCaption () : void {
    this.$el.classList.add('playing')
  }
}
