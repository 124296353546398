
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ILink } from '../../../shared/general/interfaces/ILink'
import { ITeaserService } from '../../../shared/general/interfaces/ITeaserService'
import BaseTeaser from '../../base/BaseTeaser.vue'

@Component({
  name: 'TeaserServiceElement',
  components: { BaseTeaser },
})
export default class TeaserServiceElement extends Vue {
  @Prop({ required: true }) service! : ITeaserService

  private linkData = {
    iconName: 'chevron-right',
    globalLabelKey: 'services_details_label',
  }

  private get link () : ILink {
    return {
      ...this.linkData,
      url: this.service.url,
    }
  }
}
