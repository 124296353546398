
import { MetaInfo } from 'vue-meta'
import {
  Component, mixins,
} from 'nuxt-property-decorator'
import BasePage from '../base/BasePage.vue'
import BaseLocationPage from '../base/BaseLocationPage.vue'
import LifespanAwareContentWrapper from '../wrappers/LifespanAwareContentWrapper'

@Component({
  name: 'PtLocationPage',
  components: {
    LifespanAwareContentWrapper,
    StageLocationPage: () => import('../../stage/location/StageLocationPage.vue'),
    NextLocationsMVP: () => import('../../NextLocationsMVP.vue'),
    LocationContactInformation: () => import('../../LocationContactInformation.vue'),
    BaseAccordionOpeningHours: () => import('../../base/accordion/BaseAccordionOpeningHours.vue'),
    BaseToolbarWrapper: () => import('../../base/toolbar/BaseToolbarWrapper.vue'),
    TeaserRcWrapper: () => import('../../teaser/rc/TeaserRcWrapper.vue'),
    TheBreadcrumb: () => import('../../layouts/TheBreadcrumb.vue'),
    MessageBar: () => import('../../MessageBar.vue'),
  },
})
export default class PtLocationPage extends mixins(BasePage, BaseLocationPage) {
  head () : MetaInfo {
    if (process.client) window.dispatchEvent(new CustomEvent('titleUpdated', { detail: { title: this.pageTitle } }))

    return {
      title: this.payload.pageTitle,
      meta: this.metaTags,
      link: this.links,
    }
  }
}
