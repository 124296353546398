
import { Component } from 'nuxt-property-decorator'
import BaseContentProjection from '../../base/BaseContentProjection.vue'
import ContentProjection from '../../../ContentProjection.vue'

@Component({
  name: 'TtService',
  components: { ContentProjection },
})
export default class TtService extends BaseContentProjection {
  beforeCreate () {
    this.type = 'service'
  }

  private get name () {
    return this.payload.tt_name || ''
  }
}
