
import {
  Component, Emit, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import IHeaderNavElement from '../../shared/general/interfaces/IHeaderNavElement'
import ELinkTarget from '../../shared/general/enums/ELinkTarget'
import isExternalURL from '../../shared/fsxa/services/ExternalLinkService'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import HeaderDekraLogo from './HeaderDekraLogo.vue'
import { ILink } from '../../shared/general/interfaces/ILink'

@Component({
  name: 'HeaderLowerSection',
  components: {
    BaseGridLayout,
    HeaderDekraLogo,
    HeaderMenuIconButton: () => import('./HeaderMenuIconButton.vue'),
    BaseLink: () => import('../base/BaseLink.vue'), // used in navElementProps & header getter
  },
})
export default class HeaderLowerSection extends Vue {
  @Prop({ required: true }) headerNavElements! : IHeaderNavElement[]

  @Prop({ required: true }) isSearchbarVisible! : boolean

  @Prop({ required: true }) isHamburgerMenuVisible! : boolean

  private useHamburgerMenu : boolean = false

  private navReady : boolean = false

  private resizeObserver : ResizeObserver | null = null

  mounted () {
    this.$nextTick(() => {
      const menus = this.$refs.menus as HTMLElement
      const nav = this.$refs.nav as HTMLElement

      if (menus && nav) {
        this.resizeObserver = new ResizeObserver(() => {
          // reset burger and wait for it to be rendered to then recheck if it's too big
          this.useHamburgerMenu = false
          this.$nextTick(() => {
            this.useHamburgerMenu = nav.getBoundingClientRect().right > menus.getBoundingClientRect().right + 1
            this.emitUseBurgerMenu(this.useHamburgerMenu)
            this.navReady = true
          })
        })
        this.resizeObserver.observe(menus)
      }
    })
  }

  private get searchLabel () : string {
    return globalLabelAsString('search_label')
  }

  private get menuLabel () : string {
    return globalLabelAsString('mobile_nav_label')
  }

  private hasChildren (navElement : IHeaderNavElement) : boolean {
    return !!navElement.children?.length
  }

  private navElementProps (navElement : IHeaderNavElement) {
    const hasChildren = this.hasChildren(navElement)
    const is = hasChildren ? 'li' : 'BaseLink'
    const url = hasChildren ? '' : navElement.path
    const internal = url ? !isExternalURL(url) : true

    const result = {
      is,
      url,
      target: internal ? ELinkTarget.Self : ELinkTarget.Blank,
    } as ILink

    if (!hasChildren) {
      result.onlySlotContent = true
    }

    return result
  }

  private activeElementStyle (navElement : IHeaderNavElement) : string {
    if (navElement.isActive) {
      return this.$store.state.Navigation.path[1] === navElement.key
        ? 'text-white bg-darkgreen-600'
        : 'text-white bg-darkgreen'
    }

    // 0 is the static "homepage" link
    if (this.$store.state.Navigation.path[1] === navElement.key) {
      return 'text-darkgreen bg-darkgreen-100 hover:text-darkgreen-700 hover:bg-darkgreen-200 active:text-darkgreen-800 '
      + 'active:bg-darkgreen-300 focus-visible:bg-darkgreen-100'
    }

    return 'hover:text-darkgreen-600 hover:bg-darkgreen-50 active:bg-darkgreen-100 active:text-darkgreen-600 '
    + 'focus-visible:ring focus-visible:ring-black focus-visible:bg-darkgreen-50 focus-visible:text-darkgreen-600'
  }

  @Watch('headerNavElements', { deep: true })
  private onHeaderNavElementsChange (navElements : IHeaderNavElement[]) : void {
    navElements.forEach((navElement, index) => {
      this.headerNavElements[index].isActive = navElement.isActive
      this.headerNavElements[index].isPath = navElement.isPath
    })
  }

  @Emit('receive-use-burger-menu')
  private emitUseBurgerMenu (useBurgerMenu : boolean) : boolean {
    return useBurgerMenu
  }

  @Emit('toggle-searchbar')
  private toggleSearchbar () : boolean {
    return true
  }

  @Emit('toggle-hamburger-menu')
  private toggleHamburgerMenu () : boolean {
    return true
  }

  @Emit('click-nav-element')
  private clickNavElement (navElement : IHeaderNavElement) : IHeaderNavElement {
    return navElement
  }
}
