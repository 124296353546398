
import { Component } from 'nuxt-property-decorator'
import { Section } from 'fsxa-api'
import { FSXABaseSection } from 'fsxa-pattern-library'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import { formatJoinFromToDate } from '../../../shared/general/services/DateTimeService'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'
import { TContentProjection } from '../../../shared/general/types/TContentProjection'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'

interface IPayload {
  tt_show_in_this_language ?: boolean
  tt_date_from ?: string
  tt_date_to ?: string
  tt_headline ?: string
  tt_location ?: string
  tt_page_content ?: Section[]
  tt_contact ?: Section[]
  tt_download_list ?: Section[]
  tt_link_list ?: Section[]
  tt_media_download_teaser ?: Section[]
  tt_overline ?: string
  tt_publish_from ?: string
  tt_publish_to ?: string
  tt_seperation_line ?: Section[]
  tt_text_image_1 ?: Section[]
  tt_text_image_2 ?: Section[]
  tt_tags ?: ITagData[]
  tt_tags_remote ?: TRemoteDatasetIndex
  tt_show_labels ?: boolean
  tt_author ?: string
  tt_name ?: string
}

@Component({ name: 'BaseContentProjection' })
export default class BaseContentProjection extends FSXABaseSection<IPayload> {
  protected type ?: TContentProjection

  async mounted () {
    await this.fetchRemoteDatasets()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasets()
  }

  async fetchRemoteDatasets () : Promise<void> {
    await getOrFetchRemoteDatasets(this.tagsRemoteDatasetIndex)
  }

  protected get tagsRemote () : string[] {
    return getRemoteDatasetsFromStore(this.tagsRemoteDatasetIndex)
      .map((tag) => tag?.data?.tt_name || '')
      .filter((name) => name.length)
  }

  public get showInThisLanguage () : boolean {
    return !!this.payload.tt_show_in_this_language
  }

  public get headline () : string {
    return this.payload.tt_headline || ''
  }

  public get overline () : string {
    return this.payload.tt_overline || ''
  }

  public get location () : string {
    return this.payload.tt_location || ''
  }

  public get author () : string {
    return this.payload.tt_author || ''
  }

  private get showArticleLabels () : boolean {
    return !!this.payload.tt_show_labels
  }

  public get tags () : string[] {
    if (!this.showArticleLabels && this.type !== 'press_release') return []
    return this.payload.tt_tags?.map((tag) => tag?.data?.tt_name || '').filter((name) => name.length) || []
  }

  public get tagsRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    if (!this.showArticleLabels && this.type !== 'press_release') return undefined
    return this.payload.tt_tags_remote
  }

  public get dateFrom () : string {
    return this.payload.tt_date_from || this.payload.tt_publish_from || ''
  }

  public get dateTo () : string {
    return this.payload.tt_date_to || ''
  }

  public get formattedDateFrom () : string {
    return formatJoinFromToDate({ from: this.dateFrom })
  }

  public get formattedDateFromDateTo () : string {
    return formatJoinFromToDate({ from: this.dateFrom, to: this.dateTo })
  }

  public get displayArticleDate () : string {
    if (!this.payload.tt_publish_from) return ''
    return formatJoinFromToDate({ from: new Date(this.payload.tt_publish_from).toISOString() })
  }

  public get catalogPageContent () : Section[] {
    return this.payload.tt_page_content || []
  }

  public get pressReleaseSections () : Section[] {
    return [
      this.payload.tt_text_image_1,
      this.payload.tt_text_image_2,
      this.payload.tt_contact,
      this.payload.tt_seperation_line,
      this.payload.tt_media_download_teaser,
      this.payload.tt_download_list,
      this.payload.tt_link_list,
    ].flatMap((section) => (section ? [section] : [])).flat()
  }

  private get overviewPageRefId () : string {
    switch (this.type) {
      case 'article':
        return this.globalSettings?.data?.ps_country_project_settings?.pt_article_overview_page?.referenceId || ''
      case 'event':
        return this.globalSettings?.data?.ps_country_project_settings?.pt_event_overview_page?.referenceId || ''
      case 'press_release':
        return this.globalSettings?.data?.ps_country_project_settings?.pt_press_overview_page?.referenceId || ''
      default:
        return ''
    }
  }

  public get overviewPageLink () : string {
    return this.getUrlByPageId(this.overviewPageRefId) || '#'
  }

  public get overviewPageLabel () : string {
    return globalLabelAsString(`show_all_${this.type?.replace('s_release', '')}s_label`)
  }
}
