enum EButtonVariant {
    Default = 'default',
    Outline = 'outline',
    Ghost = 'ghost',
    Neutral = 'neutral',
    NeutralOutline = 'neutralOutline',
    NeutralGhost = 'neutralGhost',
    Accent = 'accent',
}

export default EButtonVariant
