
import { Component, Prop } from 'nuxt-property-decorator'
import Vue from 'vue'
import { RichTextElement } from 'fsxa-api'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { globalLabel, globalLabelAsString } from '../../../shared/general/services/StoreService'

@Component({
  name: 'TeaserArticlesHome',
  components: {
    BaseIcon: () => import('../../base/BaseIcon.vue'),
    BaseButton: () => import('../../base/BaseButton.vue'),
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
    BaseGridLayout,
    TeaserResults: () => import('../TeaserResults.vue'),
    GlobalLabelWrapper: () => import('../../GlobalLabelWrapper.vue'),
  },
})
export default class TeaserArticlesHome extends Vue {
  @Prop() headline ?: string

  @Prop({ required: true }) overviewPageUrl! : string

  @Prop({ required: true }) showLoadingAnimation! : boolean

  @Prop({ default: () => [] }) teasers! : ITeaserData[]

  private get noArticlesAvailableLabel () : string | RichTextElement[] {
    return globalLabel('no_articles_available_label')
  }

  public get overviewPageLabel () : string {
    return globalLabelAsString('show_all_articles_label')
  }
}
