import { FSXAApi, ProjectProperties } from 'fsxa-api'
import { TRemoteDatasetIndex } from '../types/TRemoteDataset'
import getOrFetchRemoteDatasets from './RemoteDatasetService'

let projectName : string | null = null

// eslint-disable-next-line import/prefer-default-export
export const getCurrentProjectName = async (fsxa : FSXAApi, locale : string) : Promise<string | null> => {
  if (projectName) return projectName

  const properties = await fsxa.fetchProjectProperties({ locale }) as (ProjectProperties | null)
  const remoteIndex = properties?.data?.ps_project_remote as (TRemoteDatasetIndex | undefined)
  const projects = await getOrFetchRemoteDatasets(remoteIndex)

  projectName = projects[0]?.data?.tt_project_name || null

  return projectName
}
