
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { globalLabel } from '../../../shared/general/services/StoreService'
import { ILink } from '../../../shared/general/interfaces/ILink'
import cutOff from '../../../shared/general/services/CutOffService'
import BaseHeadline from '../../base/BaseHeadline.vue'

@Component({
  name: 'TeaserEventsHomeOverview',
  components: {
    BaseGridLayout,
    BaseHeadline,
    BaseLink: () => import('../../base/BaseLink.vue'),
    BaseTeaserHome: () => import('../../base/BaseTeaserHome.vue'),
    GlobalLabelWrapper: () => import('../../GlobalLabelWrapper.vue'),
  },
})
export default class TeaserEventsHomeOverview extends Vue {
  @Prop() headline ?: string

  @Prop() allEventsLink ?: ILink

  @Prop({ default: () => [] }) teasers! : ITeaserData[]

  private cut = cutOff

  private get noEventsText () : string | RichTextElement[] {
    return globalLabel('no_events_available_label')
  }

  private get gridCols () : string {
    return this.teasers.length === 1 ? 'lg:col-span-8 lg:col-start-3' : ''
  }
}
