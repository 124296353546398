
import { FSXABaseSection, FSXARenderContentElement } from 'fsxa-pattern-library'
import { Section } from 'fsxa-api'
import { Component } from 'nuxt-property-decorator'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'

interface IPayload {
  st_left_content : Section[]
  st_right_content : Section[]
}

@Component({
  name: 'st_container_2_col',
  components: {
    BaseGridLayout,
    FSXARenderContentElement,
  },
})
export default class StContainer2Col extends FSXABaseSection<IPayload> {
  get leftContent () : Section[] {
    return this.payload.st_left_content
  }

  get rightContent () : Section[] {
    return this.payload.st_right_content
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }
}
