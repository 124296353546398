
import {
  Component, InjectReactive, Prop, Vue,
} from 'nuxt-property-decorator'
import { ITeaserService } from '../../../shared/general/interfaces/ITeaserService'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import BaseButton from '../../base/BaseButton.vue'
import TeaserServiceElement from './TeaserServiceElement.vue'

@Component({
  name: 'TeaserServiceOverview',
  components: {
    BaseButton,
    TeaserServiceElement,
    BaseHeadline: () => import('../../base/BaseHeadline.vue'),
  },
})
export default class TeaserServiceOverview extends Vue {
  @InjectReactive({ from: 'gridSize' }) gridSize! : 'small' | 'wide'

  @Prop() anchorName ?: string

  @Prop() headline ?: string

  @Prop({ default: () => [] }) services! : ITeaserService[]

  @Prop({ default: 8 }) batchSize! : number

  @Prop({ default: false }) showLoadMoreButton! : boolean

  private maxServices : number = this.batchSize

  private get loadMoreLabel () : string {
    return globalLabelAsString('load_more_label')
  }

  private get visibleServices () : ITeaserService[] {
    const { locale } = this.$store.state.Locale
    return this.services
      .sort((first, second) => first.headline.localeCompare(second.headline, locale))
      .slice(0, this.maxServices)
  }

  private showMoreTeasers () : void {
    this.maxServices += this.batchSize
  }
}
